import {
  AddAdminUserParams,
  DemoRequestParams,
  UserLoginParams,
  UserPaginationParams,
} from './user.model';

const CONTEXT = '[UserStore]';
export class UserLogin {
  static readonly type = `${CONTEXT} Login`;
  constructor(public payload: UserLoginParams) {}
}

export class DemoRequest {
  static readonly type = `${CONTEXT} Submit Demo Request`;
  constructor(public payload: DemoRequestParams) {}
}

export class ValidateOtp {
  static readonly type = `${CONTEXT} Request OTP`;
  constructor(
    public userId: number,
    public code: number
  ) {}
}

export class ResendOtp {
  static readonly type = `${CONTEXT} Resend OTP`;
  constructor(public userId: number) {}
}

export class GetUser {
  static readonly type = `${CONTEXT} Get User`;
}

export class GetUserList {
  static readonly type = `${CONTEXT} Get User List`;
  constructor(public payload: UserPaginationParams) {}
}

export class AddAdminUser {
  static readonly type = `${CONTEXT} Add Admin User`;
  constructor(public payload: AddAdminUserParams) {}
}

export class ForgotPassword {
  static readonly type = `${CONTEXT} Forgot Password`;
  constructor(public email: string) {}
}

export class ResetPassword {
  static readonly type = `${CONTEXT} Reset Password`;
  constructor(
    public token: string,
    public password: string
  ) {}
}
