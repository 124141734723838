<p class="text-4xl font-semibold">Request Demo</p>
<p-stepper orientation="vertical">
  <p-stepperPanel header="Organization Information">
    <ng-template
      pTemplate="content"
      let-nextCallback="nextCallback"
      let-index="index">
      <form [formGroup]="organizationForm">
        <p class="text-base font-normal">Organization Name</p>
        <input
          type="text"
          formControlName="name"
          placeholder="Organization Name"
          pInputText
          class="w-full"
          [class.input-danger]="
            organizationForm.get('name')?.invalid &&
            organizationForm.get('name')?.touched
          " />
        <app-error-message
          [control]="organizationForm.get('name')"
          label="Organization Name"></app-error-message>

        <p class="text-base font-normal">Organization Type</p>
        <p-dropdown
          formControlName="type"
          [options]="organizationTypes"
          placeholder="Select Organization Type"
          class="w-full"
          [class.input-danger]="
            organizationForm.get('type')?.invalid &&
            organizationForm.get('type')?.touched
          " />
        <app-error-message
          [control]="organizationForm.get('type')"
          label="Organization Type"></app-error-message>

        <p class="text-base font-normal">Organization Email Address</p>
        <input
          type="email"
          formControlName="email"
          placeholder="example@example.com"
          pInputText
          class="w-full"
          [class.input-danger]="
            organizationForm.get('email')?.invalid &&
            organizationForm.get('email')?.touched
          " />
        <app-error-message
          [control]="organizationForm.get('email')"
          label="Organization Email Address"></app-error-message>

        <p class="text-base font-normal">Country</p>
        <p-dropdown
          formControlName="countryCode"
          [options]="countries"
          optionLabel="name"
          placeholder="Select Country"
          [virtualScroll]="true"
          [itemSize]="30"
          class="w-full"
          filter="true"
          filterBy="name"
          [class.input-danger]="
            organizationForm.get('countryCode')?.invalid &&
            organizationForm.get('countryCode')?.touched
          " />
        <app-error-message
          [control]="organizationForm.get('countryCode')"
          label="Country"></app-error-message>

        <p class="text-base font-normal">Organization Phone</p>
        <p-inputGroup>
          <p-inputGroupAddon>
            <strong>{{
              organizationForm.get('countryCode')?.value.dialCode ?? '-'
            }}</strong>
          </p-inputGroupAddon>
          <input
            type="text"
            formControlName="phone"
            placeholder="1233445567"
            pInputText
            class="w-full"
            [class.input-danger]="
              organizationForm.get('phone')?.invalid &&
              organizationForm.get('phone')?.touched
            " />
        </p-inputGroup>
        <app-error-message
          [control]="organizationForm.get('phone')"
          label="Organization Phone"></app-error-message>

        <p class="text-base font-normal">Business Address</p>
        <input
          type="text"
          formControlName="address"
          placeholder="Chicago"
          pInputText
          class="w-full"
          [class.input-danger]="
            organizationForm.get('address')?.invalid &&
            organizationForm.get('address')?.touched
          " />
        <app-error-message
          [control]="organizationForm.get('address')"
          label="Business Address"></app-error-message>
      </form>

      <app-action-button
        [disabled]="!organizationForm.valid"
        (actionClicked)="nextCallback.emit()"
        label="Continue"></app-action-button>
    </ng-template>
  </p-stepperPanel>
  <p-stepperPanel header="Personal Information">
    <ng-template
      pTemplate="content"
      let-nextCallback="nextCallback"
      let-index="index">
      <form [formGroup]="personalForm">
        <p class="text-base font-normal !mt-8">First Name</p>
        <input
          type="text"
          formControlName="firstName"
          placeholder="John"
          pInputText
          class="w-full"
          [class.input-danger]="
            personalForm.get('firstName')?.invalid &&
            personalForm.get('firstName')?.touched
          " />
        <app-error-message
          [control]="personalForm.get('firstName')"
          label="First Name"></app-error-message>

        <p class="text-base font-normal">Last Name</p>
        <input
          type="text"
          formControlName="lastName"
          placeholder="Wick"
          pInputText
          class="w-full"
          [class.input-danger]="
            personalForm.get('lastName')?.invalid &&
            personalForm.get('lastName')?.touched
          " />
        <app-error-message
          [control]="personalForm.get('lastName')"
          label="Last Name"></app-error-message>
      </form>

      <app-action-button
        [disabled]="!organizationForm.valid || !personalForm.valid"
        [actionInProgress]="processing$()"
        (actionClicked)="requestDemo()"
        label="Submit Request"></app-action-button>
    </ng-template>
  </p-stepperPanel>
</p-stepper>
