<p-sidebar
  [ngClass]="'right-form ' + widthClass"
  [(visible)]="isVisible"
  position="right"
  (onHide)="sidebarHidden.emit(); clearUploadedFiles()">
  <ng-template pTemplate="header">
    <span class="font-normal text-xl">{{ title }}</span>
  </ng-template>
  <ng-content></ng-content>
</p-sidebar>
