@if (currUser() && notAnAuthRoute()) {
  <app-header (toggleSidebar)="toggleSidebar()"></app-header>
  <div class="p-grid flex overflow-auto bg-black">
    <div class="p-col-fixed">
      <app-sidebar [isExpanded]="isSidebarExpanded"></app-sidebar>
    </div>
    <div class="w-full overflow-auto bg-white main-layout">
      <router-outlet></router-outlet>
    </div>
  </div>
} @else {
  <router-outlet></router-outlet>
}
