import { Component, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { InviteVendorUserFormComponent } from '../../../components/form-components/invite-vendor-user-form/invite-vendor-user-form.component';
import { TabViewModule } from 'primeng/tabview';
import { SidebarData } from '../../../../store/general-store/general.model';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InvitationListComponent } from './invitation-list/invitation-list.component';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ActiveUserListComponent } from './active-user-list/active-user-list.component';
import { AccessDirective } from '../../../directives/role-based-access.directive';
import { Store } from '@ngxs/store';
import { GetRoles } from '../../../../store/general-store/general.actions';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    ButtonModule,
    RightFormBaseComponent,
    InviteVendorUserFormComponent,
    TabViewModule,
    ConfirmDialogModule,
    InvitationListComponent,
    ActiveUserListComponent,
    AccessDirective,
  ],
  providers: [ConfirmationService],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss',
})
export class UserListComponent {
  @ViewChild(InvitationListComponent)
  invitationListComponent!: InvitationListComponent;

  pageTitle = '';
  sidebar: SidebarData = {
    visible: false,
    title: '',
    type: '',
    width: 'w-25',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
  }

  getUpdatedData() {
    this.store.dispatch(new GetRoles());
    this.sidebar = {
      visible: true,
      title: 'Invite New User',
      type: 'INVITE',
      width: 'w-25',
    };
  }
}
