import { inject, Injectable, Signal } from '@angular/core';
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../store/users-store/user.selectors';
import { MergedPermission } from '../../store/users-store/user.model';

@Injectable({
  providedIn: 'root',
})
class UserRoles {
  userPermissions$: Signal<MergedPermission[] | []> = this.store.selectSignal(
    UserStateSelectors.getUserPermissions
  );

  constructor(
    private store: Store,
    private router: Router
  ) {}

  get userPermissions(): MergedPermission[] {
    return this.userPermissions$().length > 0
      ? this.userPermissions$()
      : this.getDefaultPermissions();
  }

  private getDefaultPermissions(): MergedPermission[] {
    return [
      { slug: 'list-product', allowed: true },
      { slug: 'list-product-data', allowed: true },
      { slug: 'list-product-design', allowed: true },
      { slug: 'list-product-qr', allowed: true },
      { slug: 'list-qr-download', allowed: true },
      { slug: 'list-qr-order', allowed: true },
      { slug: 'list-role', allowed: true },
      { slug: 'list-user', allowed: true },
      { slug: 'list-vendor', allowed: true },
      { slug: 'edit-product', allowed: true },
      { slug: 'edit-product-design', allowed: true },
      { slug: 'edit-product-qr', allowed: true },
      { slug: 'edit-role', allowed: true },
      { slug: 'edit-user', allowed: true },
      { slug: 'edit-vendor', allowed: true },
    ];
  }
  public navigate() {
    this.router.navigate(['/no-access']);
  }
}
@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  checkPermissions(
    currentUser: UserRoles,
    requiredPermissions: string[]
  ): boolean {
    const permissions = currentUser.userPermissions;
    const hasPermission = requiredPermissions.every(permission =>
      permissions.some(p => p.slug === permission && p.allowed)
    );
    if (hasPermission === false) {
      currentUser.navigate();
    }
    return hasPermission;
  }
}
export const canActivateTeam: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const requiredPermissions = route.data['requiredPermissions'] as string[];

  return inject(PermissionsService).checkPermissions(
    inject(UserRoles),
    requiredPermissions
  );
};
