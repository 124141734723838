<form [formGroup]="form">
  <p class="text-base font-normal text-black mt-6 pb-2">CTE Title</p>
  <input
    pInputText
    class="w-full"
    [class.input-danger]="
      form.get('title')?.invalid && form.get('title')?.touched
    "
    placeholder="Enter Title"
    formControlName="title" />
  <app-error-message
    [control]="form.get('title')"
    label="CTE Title"></app-error-message>
  <p class="text-base font-normal text-black mt-6 pb-2">CTE Description</p>
  <textarea
    rows="5"
    class="w-full resize-none"
    pInputTextarea
    [class.input-danger]="
      form.get('description')?.invalid && form.get('description')?.touched
    "
    formControlName="description"
    placeholder="Add description here"></textarea>
  <app-error-message
    [control]="form.get('description')"
    label="CTE Description"></app-error-message>

  <div>
    <p class="font-normal text-black text-base mt-6 pb-4">Select CTE Icon</p>
    <div
      *ngIf="cdeIcons$ | async as cdeIcons"
      class="grid grid-cols-8 gap-x-3 gap-y-3">
      @for (icon of cdeIcons; track icon) {
        <div
          class="relative border-dashed border border-gray-300 w-12 h-12 rounded-md cursor-pointer flex justify-center items-center icon-box"
          (click)="selectIcon(icon)"
          [ngClass]="{ selected: selectedIcon === icon.value }">
          <i [class]="icon.value" class="text-2xl"></i>
          @if (selectedIcon === icon.value) {
            <div
              class="selected-check bg-blue-600 rounded-full inline-flex items-center justify-center absolute -top-2 -right-2 w-5 h-5">
              <i class="pi pi-check text-white font-bold"></i>
            </div>
          }
        </div>
      }
    </div>
  </div>
</form>
<div class="sidebar-form-button">
  <app-action-button
    [disabled]="!this.form.valid"
    [actionInProgress]="processing$()"
    (actionClicked)="action()"
    [label]="type === 'EDIT' ? 'Update' : 'Submit'"></app-action-button>
</div>
