<div class="w-full p-8">
  <app-right-form-base
    [isVisible]="templateSidebar.visible"
    (sidebarHidden)="templateSidebar.visible = false"
    [title]="templateSidebar.title"
    [widthClass]="templateSidebar.width">
    <app-product-design-template
      [id]="templateSidebar.id"></app-product-design-template>
  </app-right-form-base>

  <p-confirmDialog></p-confirmDialog>

  @if (sidebar.visible && ['ADD', 'EDIT'].includes(sidebar.type)) {
    <app-right-form-base
      [isVisible]="sidebar.visible"
      [widthClass]="sidebar.width"
      (sidebarHidden)="sidebar.visible = false"
      [title]="sidebar.title">
      <app-product-qr-settings-form
        [data]="sidebar.data"
        [type]="sidebar.type"
        [id]="sidebar.id"
        (actionSuccess)="getProductList(); sidebar.visible = false">
      </app-product-qr-settings-form>
    </app-right-form-base>
  }

  @if (
    productSidebar.visible && ['ADD', 'EDIT'].includes(productSidebar.type)
  ) {
    <app-right-form-base
      [isVisible]="productSidebar.visible"
      (sidebarHidden)="productSidebar.visible = false"
      [title]="productSidebar.title"
      [widthClass]="productSidebar.width">
      <app-product-add-edit
        [data]="productSidebar.data"
        [type]="productSidebar.type"
        [id]="productSidebar.id"
        (actionCreateProduct)="onProductCreated()"
        (actionSuccess)="productSidebar.visible = false"></app-product-add-edit>
    </app-right-form-base>
  }

  <div class="flex justify-between items-center">
    <h2>{{ pageTitle }}</h2>
    <div class="d-flex">
      <p-button
        appAccess="add-product"
        icon="pi pi-plus"
        label="Add Product"
        (click)="
          productSidebar = {
            type: 'ADD',
            title: 'Add New Product',
            visible: true,
            width: 'w-75',
          }
        " />
    </div>
  </div>
  <app-data-table
    [first]="first"
    export="PRODUCT"
    [rows]="rows"
    [sortBy]="sortBy"
    [sortOrder]="sortOrder"
    [selectedFilters]="selectedFilters"
    [data$]="this.data$"
    [headers$]="this.headers$"
    [isProcessing$]="this.isProcessing$"
    [pagination$]="this.pagination$"
    (actionClickedEvent)="actionClicked($event)"
    (refreshEvent)="refresh($event)" />
</div>
