import { Component, OnInit, OnDestroy, Signal } from '@angular/core';
import { TimeAgoPipe } from '../../../pipes/time-ago.pipe';
import { Store } from '@ngxs/store';
import { GetActivityLogs } from '../../../../store/general-store/general.actions';
import { Activities } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-activity-feed',
  standalone: true,
  imports: [TimeAgoPipe, CardModule],
  templateUrl: './activity-feed.component.html',
  styleUrl: './activity-feed.component.scss',
})
export class ActivityFeedComponent implements OnInit, OnDestroy {
  loading = false;
  hasNextPage = true;

  activityLogs$: Signal<Activities> = this.store.selectSignal(
    GeneralStateSelectors.getActivityLogs
  );
  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(
      new GetActivityLogs({
        rows: 100,
        first: 0,
        sortBy: 'createdAt',
        sortOrder: 'DESC',
        search: '',
        filters: '',
      })
    );

    // this.subscription = this.pageSubject
    //   .pipe(
    //     distinctUntilChanged(),
    //     tap(() => (this.loading = true)),
    //     switchMap(page => this.activityService.getActivities(page))
    //   )
    //   .subscribe({
    //     next: response => {
    //       if (this.pageSubject.value === 1) {
    //         this.activities = response.data;
    //       } else {
    //         this.activities = [...this.activities, ...response.data];
    //       }
    //       this.hasNextPage = response.hasNextPage;
    //       this.loading = false;
    //     },
    //     error: error => {
    //       console.error('Error fetching activities:', error);
    //       this.loading = false;
    //     },
    //   });
  }

  ngOnDestroy() {
    console.log('destroyed');
    // this.subscription?.unsubscribe();
  }

  onScroll(event: any) {
    const element = event.target;
    const reachedBottom =
      element.scrollHeight - element.scrollTop <= element.clientHeight + 100;

    if (reachedBottom) {
      console.log('reached bottom');
    }
    // if (reachedBottom && !this.loading && this.hasNextPage) {
    //   this.pageSubject.next(this.pageSubject.value + 1);
    // }
  }
}
