import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostListener,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ActionButtonComponent } from '../../shared-components/action-button/action-button.component';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { Store } from '@ngxs/store';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { AddKDE } from '../../../../store/general-store/general.actions';
import { Data, KDE } from '../../../../store/general-store/general.model';

@Component({
  selector: 'app-add-kdeform',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ActionButtonComponent,
    ErrorMessageComponent,
    InputTextareaModule,
    CheckboxModule,
    TabViewModule,
    DropdownModule,
  ],
  templateUrl: './add-kde-form.component.html',
  styleUrl: './add-kde-form.component.scss',
})
export class AddKDEFormComponent implements OnInit {
  form: FormGroup;
  processing$ = this.store.selectSignal(
    OrganizationStateSelectors.isOrganizationStoreProcessing
  );
  @Input() cteID = 0;
  @Input() isProductKde = false;
  @Output() actionSuccess = new EventEmitter<KDE>();
  inputOptions = ['TEXT', 'NUMBER', 'DECIMAL'];
  dateInputOptions = ['DATE', 'TIME', 'DATETIME'];
  radioOptions: Data[] = [];
  dropdownOptions: Data[] = [];
  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      gs1: [false],
      fsma204: [false],
      inputType: ['', [Validators.required]],
      inputTypeOptions: [[]],
      radioKey: [''],
      radioValue: [''],
      dropdownKey: [''],
      dropdownValue: [''],
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.action();
    }
  }

  ngOnInit(): void {
    this.form.get('inputType')?.valueChanges.subscribe(value => {
      if (value === 'RADIO' || value === 'SELECT') {
        this.form.get('inputTypeOptions')?.setValidators([Validators.required]);
      } else {
        this.form.get('inputTypeOptions')?.clearValidators();
      }
      this.form.get('inputTypeOptions')?.updateValueAndValidity();
    });
  }

  addRadioOption() {
    if (this.form.value.radioKey !== '' && this.form.value.radioValue !== '') {
      this.radioOptions.push({
        key: this.form.value.radioKey,
        value: this.form.value.radioValue,
      });
      this.form.get('inputTypeOptions')?.patchValue(this.radioOptions);
      this.form.get('radioKey')?.patchValue('');
      this.form.get('radioValue')?.patchValue('');
    }
  }

  addDropdownOption() {
    if (
      this.form.value.dropdownKey !== '' &&
      this.form.value.dropdownValue !== ''
    ) {
      this.dropdownOptions.push({
        key: this.form.value.dropdownKey,
        value: this.form.value.dropdownValue,
      });
      this.form.get('inputTypeOptions')?.patchValue(this.dropdownOptions);
      this.form.get('dropdownKey')?.patchValue('');
      this.form.get('dropdownValue')?.patchValue('');
    }
  }

  deleteRadioOption(index: number) {
    this.radioOptions.splice(index, 1);
    this.form.get('inputTypeOptions')?.patchValue(this.radioOptions);
  }

  deleteDropdownOption(index: number) {
    this.dropdownOptions.splice(index, 1);
    this.form.get('inputTypeOptions')?.patchValue(this.dropdownOptions);
  }

  onTabChange(e: TabViewChangeEvent) {
    this.form.get('inputType')?.patchValue('');
    this.form.get('inputTypeOptions')?.patchValue([]);
    if (e.index === 2) {
      this.form.get('inputType')?.patchValue('RADIO');
    }
    if (e.index === 3) {
      this.form.get('inputType')?.patchValue('SELECT');
    }
    if (e.index === 4) {
      this.form.get('inputType')?.patchValue('LINK');
    }
    if (e.index === 5) {
      this.form.get('inputType')?.patchValue('FILE');
    }
  }

  action() {
    if (this.form.invalid) return;
    if (!this.isProductKde) {
      const newKde = {
        title: this.form.value.title,
        description: this.form.value.description,
        gs1: this.form.value.gs1 ?? false,
        fsma204: this.form.value.fsma204 ?? false,
        inputType: this.form.value.inputType,
        inputTypeOptions: this.form.value.inputTypeOptions,
        organizationCteId: this.cteID,
      };
      this.store.dispatch(new AddKDE(newKde)).subscribe(() => {
        this.form.reset();
        this.radioOptions = [];
        this.dropdownOptions = [];
      });
    } else {
      const newKde = {
        title: this.form.value.title,
        description: this.form.value.description,
        gs1: this.form.value.gs1 ?? false,
        fsma204: this.form.value.fsma204 ?? false,
        inputType: this.form.value.inputType,
        inputTypeOptions: this.form.value.inputTypeOptions,
        isDefault: false,
        productCteId: this.cteID,
        order: 0,
        id: 0,
      };
      this.form.reset();
      this.radioOptions = [];
      this.dropdownOptions = [];
      this.actionSuccess.emit(newKde);
    }
  }
}
