import { Selector } from '@ngxs/store';
import { ProductState } from './products.state';
import { ProductStateModel } from './products.model';

export class ProductStateSelectors {
  @Selector([ProductState])
  static getProducts(state: ProductStateModel) {
    return state.products;
  }

  @Selector([ProductState])
  static getProductItemData(state: ProductStateModel) {
    return state.productItemData;
  }

  @Selector([ProductState])
  static getAllProducts(state: ProductStateModel) {
    return state.allProducts;
  }

  @Selector([ProductState])
  static searchProducts(state: ProductStateModel) {
    return state.searchedProducts;
  }

  @Selector([ProductState])
  static getProduct(state: ProductStateModel) {
    return state.product;
  }

  @Selector([ProductState])
  static getPaginationData(state: ProductStateModel) {
    return state.pagination;
  }

  @Selector([ProductState])
  static isProcessing(state: ProductStateModel) {
    return state.isProcessing;
  }

  @Selector([ProductState])
  static getHeaders(state: ProductStateModel) {
    return state.headers;
  }

  @Selector([ProductState])
  static getSelectedProductCTEs(state: ProductStateModel) {
    return state.product?.productCtes ?? [];
  }
}
