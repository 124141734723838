// import { NgZone, inject } from '@angular/core';
import { throwError } from 'rxjs';
// import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
// import { NotificationService } from '../services/notification.service';
// import { Store } from '@ngxs/store';
// import { GetUser } from '../store/user-store/actions';

import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';

export const httpErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  // const zone = inject(NgZone);
  // const router = inject(Router);
  // const store = inject(Store);
  // const notificationService = inject(NotificationService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      // if (error.status === 401 || error.status === 406) {
      //   zone.run(() => {
      //     localStorage.clear();
      //     router.navigate(['/']);
      //   });
      // } else if (error.status === 403) {
      //   // store.dispatch(new GetUser());
      //   zone.run(() => {
      //     router.navigate(['/unauthorized']);
      //   });
      // } else if (req.method === 'GET') console.log(error.error?.message);
      // // notificationService.openErrorSnackBar(error.error?.message);
      return throwError(() => error);
    })
  );
};
