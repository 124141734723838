import { Selector } from '@ngxs/store';
import { GeneralStateModel } from './general.model';
import { GeneralState } from './general.state';

export class GeneralStateSelectors {
  @Selector([GeneralState])
  static getCountries(state: GeneralStateModel) {
    return state.countries;
  }

  @Selector([GeneralState])
  static getOrganizationTypes(state: GeneralStateModel) {
    return state.organizationTypes;
  }

  @Selector([GeneralState])
  static getDesignations(state: GeneralStateModel) {
    return state.designations;
  }

  @Selector([GeneralState])
  static getRoles(state: GeneralStateModel) {
    return state.roles;
  }

  @Selector([GeneralState])
  static getCities(state: GeneralStateModel) {
    return state.cities;
  }

  @Selector([GeneralState])
  static isGeneralStoreProcessing(state: GeneralStateModel) {
    return state.isLoading;
  }

  @Selector([GeneralState])
  static getPresignedUrls(state: GeneralStateModel) {
    return state.uploadedFilesMap;
  }

  @Selector([GeneralState])
  static shouldHideSideMenu(state: GeneralStateModel) {
    return state.hideSideMenu;
  }

  @Selector([GeneralState])
  static getCDEIcons(state: GeneralStateModel) {
    return state.CDEIcons;
  }
  @Selector([GeneralState])
  static getProductCteStats(state: GeneralStateModel) {
    return state.productStatistics;
  }
  @Selector([GeneralState])
  static getActivityLogs(state: GeneralStateModel) {
    return state.activities;
  }
}
