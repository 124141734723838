import { Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login/login.component';
import { RequestDemoPageComponent } from './pages/request-demo/request-demo.component';
import { OTPPageComponent } from './pages/otppage/otppage.component';
import { SignUpPageComponent } from './pages/sign-up-page/sign-up-page.component';
import { OrganizationListComponent } from './pages/organizations/organization-list/organization-list.component';
import { OrganizationDemoRequestsComponent } from './pages/organizations/organization-demo-requests/organization-demo-requests.component';
import { AnalyticalDashboardComponent } from './pages/dashboards/analytical-dashboard/analytical-dashboard.component';
import { AdminUserListComponent } from './pages/users/admin-user-list/admin-user-list.component';
import { VendorListComponent } from './pages/vendors/vendor-list/vendor-list.component';
import { UserListComponent } from './pages/users/user-list/user-list.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { RolesComponent } from './pages/settings/roles/roles.component';
import { NotificationsComponent } from './pages/settings/notifications/notifications.component';
import { ProfileSecurityComponent } from './pages/settings/profile-security/profile-security.component';
import { CtesKdesComponent } from './pages/settings/ctes-kdes/ctes-kdes.component';
import { ProductListComponent } from './pages/products/product-list/product-list.component';
import { ProductAddEditComponent } from './pages/products/product-add-edit/product-add-edit.component';
import { AddProductFormComponent } from './components/form-components/add-product-form/add-product-form.component';
import { QrOrderListComponent } from './pages/qr/qr-order-list/qr-order-list.component';
import { ProductItemDataListComponent } from './pages/products/product-item-data-list/product-item-data-list.component';
import { canActivateTeam } from './guards/role.guard';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NoAccessComponent } from './components/shared/no-access/no-access.component';

export const routes: Routes = [
  {
    path: 'organizations',
    children: [
      {
        path: 'list',
        component: OrganizationListComponent,
        data: {
          title: 'Organization List',
        },
      },
      {
        path: 'demo-requests',
        component: OrganizationDemoRequestsComponent,
        data: {
          title: 'Organization Demo Requests',
        },
      },
    ],
  },
  {
    path: 'vendors',
    children: [
      {
        path: 'list',
        component: VendorListComponent,
        data: {
          title: 'Vendor List',
          requiredPermissions: ['list-vendor'],
        },
        canActivate: [canActivateTeam],
      },
    ],
  },
  {
    path: 'users',
    children: [
      {
        path: 'list',
        component: UserListComponent,
        data: {
          title: 'User List',
          requiredPermissions: ['list-user'],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'admin',
        component: AdminUserListComponent,
        data: {
          title: 'Admin User List',
        },
      },
    ],
  },

  {
    path: 'products',
    children: [
      {
        path: 'list',
        component: ProductListComponent,
        data: {
          title: 'Product List',
          requiredPermissions: ['list-product'],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'data',
        component: ProductItemDataListComponent,
        data: {
          title: 'Product Item Data List',
          requiredPermissions: ['list-product-data'],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'add',
        component: ProductAddEditComponent,
        data: {
          title: 'Add New Product',
          requiredPermissions: ['add-product'],
        },
        canActivate: [canActivateTeam],
        children: [
          { path: '', redirectTo: 'product-passport', pathMatch: 'full' },
          {
            path: 'product-passport',
            component: AddProductFormComponent,
            data: {
              title: 'Product Passport',
            },
          },
        ],
      },
      {
        path: 'edit/:id',
        component: ProductAddEditComponent,
        data: {
          title: 'Edit Product',
          requiredPermissions: ['edit-product'],
        },
        canActivate: [canActivateTeam],
        children: [
          { path: '', redirectTo: 'product-passport', pathMatch: 'full' },
          {
            path: 'product-passport',
            component: AddProductFormComponent,
            data: {
              title: 'Product Passport',
            },
          },
          {
            path: 'define-ctes',
            component: CtesKdesComponent,
            data: {
              title: 'Define CTEs',
            },
          },
        ],
      },
    ],
  },

  {
    path: 'settings',
    component: SettingsComponent,
    data: {
      title: 'Settings',
      requiredPermissions: ['list-cte-kde', 'list-role'],
    },
    canActivate: [canActivateTeam],
    children: [
      { path: '', redirectTo: 'roles', pathMatch: 'full' },
      {
        path: 'profile-security',
        component: ProfileSecurityComponent,
      },
      {
        path: 'roles',
        component: RolesComponent,
        data: {
          requiredPermissions: ['list-role'],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
      },
      {
        path: 'ctes-kdes',
        component: CtesKdesComponent,
        data: {
          requiredPermissions: ['list-cte-kde'],
        },
        canActivate: [canActivateTeam],
      },
    ],
  },
  {
    path: 'dashboards',
    children: [
      {
        path: 'analytical',
        component: AnalyticalDashboardComponent,
        data: {
          title: 'Analytical Dashboard',
        },
      },
    ],
  },
  {
    path: 'qr',
    children: [
      {
        path: 'qr-orders',
        children: [
          {
            path: 'list',
            component: QrOrderListComponent,
            data: {
              title: 'QR Order List',
              requiredPermissions: ['list-qr-order'],
            },
            canActivate: [canActivateTeam],
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: LoginPageComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'otp',
    component: OTPPageComponent,
  },
  {
    path: 'demo',
    component: RequestDemoPageComponent,
  },
  {
    path: 'accept-invitation',
    component: SignUpPageComponent,
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
];
