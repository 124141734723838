import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Signal,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { CornerDotType, CornerSquareType, Options } from 'ngx-qrcode-styling';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { PanelModule } from 'primeng/panel';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { CommonModule } from '@angular/common';
import {
  Product,
  ProductQrCtaConfig,
  UpdateProductQrSettingsParams,
} from '../../../../store/products-store/products.model';
import { AccordionModule } from 'primeng/accordion';
import { ImageUploadComponent } from '../../shared-components/image-upload/image-upload.component';
import { environment } from '../../../../environments/environment';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { UpdateProductQrSettings } from '../../../../store/products-store/products.actions';
import { QrPreviewComponent } from '../../shared-components/qr-preview/qr-preview.component';
import { UploadedFile } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-product-qr-settings-form',
  standalone: true,
  imports: [
    PanelModule,
    FormsModule,
    ColorPickerModule,
    ButtonModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    CommonModule,
    AccordionModule,
    ImageUploadComponent,
    TooltipModule,
    QrPreviewComponent,
    DropdownModule,
  ],
  templateUrl: './product-qr-settings-form.component.html',
  styleUrl: './product-qr-settings-form.component.scss',
})
export class ProductQrSettingsFormComponent implements OnChanges, OnInit {
  @Input() data!: Product;
  @Input() id?: number;
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter<void>();

  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);

  bucketUrl = environment.bucketUrl;
  form: FormGroup;
  colors: string[] = [
    '#000000',
    '#7EA5C0',
    '#00B4DB',
    '#80C647',
    '#FF5B8C',
    '#FFE872',
    '#FFB274',
    '#EEEEEE',
  ];
  ctaPositions: string[] = ['TOP', 'BOTTOM'];
  ctaConfig: ProductQrCtaConfig | null = null;

  dotsColor = '#000000';
  backgroundColor = '#EEEEEE';
  cornersDotColor = '#000000';
  cornersDotType: CornerDotType = 'square';
  cornersSquareColor = '#000000';
  cornersSquareType: CornerSquareType = 'square';
  centerImage = 'https://regenesis.ag/wp-content/uploads/2023/01/logo.jpg';

  qrConfig: Options = {};

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      width: ['', [Validators.required]],
      ctaLabelText: ['', [Validators.required, Validators.maxLength(200)]],
      ctaLabelPosition: ['', [Validators.required]],
      height: ['', [Validators.required]],
    });

    this.form.get('width')?.valueChanges.subscribe(value => {
      if (value !== this.form.get('height')?.value) {
        this.form.get('height')?.setValue(value);
      }
    });

    this.form.get('height')?.valueChanges.subscribe(value => {
      if (value !== this.form.get('width')?.value) {
        this.form.get('width')?.setValue(value);
      }
    });

    effect(() => {
      if (
        this.uploadedFiles$()['qrCenterImage'] &&
        this.uploadedFiles$()['qrCenterImage'].length > 0 &&
        !this.uploadedFiles$()['qrCenterImage'][0].uploading
      ) {
        this.centerImage =
          this.uploadedFiles$()['qrCenterImage'][0].objectURL ?? '';
        this.setQrConfig();
      } else {
        this.centerImage = '';
        this.setQrConfig();
      }
    });
    this.form
      .get('width')
      ?.valueChanges.pipe(takeUntilDestroyed())
      .subscribe(data => {
        this.form.get('height')?.setValue(data);
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.updateProductQrSettings();
    }
  }

  ngOnInit(): void {
    this.setQrConfig();
    this.form.valueChanges.subscribe(_res => {
      this.ctaConfig = {
        ctaLabelText: this.form.value.ctaLabelText || '',
        ctaLabelPosition: this.form.value.ctaLabelPosition || '',
      };
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.form.patchValue({
        width: this.data?.productQrSettings[0].width || '',
        height: this.data?.productQrSettings[0].height || '',
        ctaLabelText: this.data?.productQrSettings[0].ctaLabelText || '',
        ctaLabelPosition:
          this.data?.productQrSettings[0].ctaLabelPosition || '',
      });
      this.ctaConfig = {
        ctaLabelText: this.data?.productQrSettings[0].ctaLabelText || '',
        ctaLabelPosition:
          this.data?.productQrSettings[0].ctaLabelPosition || '',
      };
      if (this.data && this.data.productQrSettings.length > 0) {
        this.dotsColor = this.data.productQrSettings[0].dotsOptionsColor;
        this.backgroundColor = this.data.productQrSettings[0].backgroundColor;
        this.cornersDotColor = this.data.productQrSettings[0].cornersDotColor;
        this.cornersDotType = this.data.productQrSettings[0]
          .cornersDotType as CornerDotType;
        this.cornersSquareColor =
          this.data.productQrSettings[0].cornersSquareColor;
        this.cornersSquareType = this.data.productQrSettings[0]
          .cornersSquareType as CornerSquareType;
        this.centerImage = this.data.productQrSettings[0].centerImage;
        this.setQrConfig();
      }
    }
  }

  setQrConfig() {
    this.qrConfig = {
      data: 'https://regenesis.ag/',
      type: 'svg',
      image: this.centerImage,
      imageOptions: {
        crossOrigin: 'anonymous',
      },
      dotsOptions: {
        color: this.dotsColor,
      },
      backgroundOptions: {
        color: this.backgroundColor,
      },
      cornersDotOptions: {
        color: this.cornersDotColor,
        type: this.cornersDotType.toLowerCase() as CornerDotType,
      },
      cornersSquareOptions: {
        color: this.cornersSquareColor,
        type: this.cornersSquareType.toLowerCase() as CornerSquareType,
      },
    };
  }

  resetQr() {
    this.centerImage =
      'https://regenesis.ag/wp-content/uploads/2023/01/logo.jpg';
    this.dotsColor = '#000000';
    this.backgroundColor = '#EEEEEE';
    this.cornersDotColor = '#000000';
    this.cornersDotType = 'square';
    this.cornersSquareColor = '#000000';
    this.cornersSquareType = 'square';
    this.setQrConfig();
  }

  updateProductQrSettings() {
    const id = this.data?.productQrSettings[0].id;
    if (!id) return;
    const payload: UpdateProductQrSettingsParams = {
      productId: this.data?.id,
      centerImage: this.centerImage,
      drawType: 'SVG',
      width: this.form.value.width,
      height: this.form.value.height,
      margin: 1,
      qrOptionsTypeNumber: 0,
      qrOptionsMode: 'NUMERIC',
      errorCorrectionLevel: 'H',
      dotsOptionsColor: this.dotsColor,
      cornersSquareType: this.cornersSquareType.toUpperCase(),
      cornersSquareColor: this.cornersSquareColor,
      cornersDotType: this.cornersDotType.toUpperCase(),
      cornersDotColor: this.cornersDotColor,
      removeRegenesisLogo: false,
      backgroundColor: this.backgroundColor,
      ctaLabelText: this.form.value.ctaLabelText,
      ctaLabelPosition: this.form.value.ctaLabelPosition,
    };
    this.store
      .dispatch(new UpdateProductQrSettings(id, payload))
      .subscribe(() => {
        this.form.reset();
        this.actionSuccess.emit();
      });
  }
}
