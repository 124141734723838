import { Component, Signal, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { User } from '../../../../../store/users-store/user.model';
import {
  Header,
  Pagination,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../../store/general-store/general.model';
import { UserStateSelectors } from '../../../../../store/users-store/user.selectors';
import { Store } from '@ngxs/store';
import { GetUserList } from '../../../../../store/users-store/user.actions';
import { ObjectToUrlParams } from '../../../../utilities/global.function';
import { DataTableComponent } from '../../../../components/shared-components/data-table/data-table.component';

@Component({
  selector: 'app-active-user-list',
  standalone: true,
  imports: [ButtonModule, DataTableComponent, ConfirmDialogModule],
  providers: [ConfirmationService],
  templateUrl: './active-user-list.component.html',
  styleUrl: './active-user-list.component.scss',
})
export class ActiveUserListComponent implements OnInit {
  pageTitle = '';
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  //Todo: Add proper type here
  selectedFilters: Record<string, any> = {};

  data$: Signal<User[]> = this.store.selectSignal(UserStateSelectors.getUsers);

  pagination$: Signal<Pagination> = this.store.selectSignal(
    UserStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    UserStateSelectors.isLoading
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    UserStateSelectors.getHeaders
  );

  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store,
    private confirmationService: ConfirmationService
  ) {
    this.getUserList();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  getUserList() {
    this.store.dispatch(
      new GetUserList({
        first: 0,
        rows: 5,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
        role: '',
      })
    );
  }

  actionClicked(_action: TableActionEvent) {
    return;
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getUserList();
  }
}
