<p class="text-4xl font-semibold">Sign In</p>
<form [formGroup]="form">
  <p class="text-base font-normal !mt-10">Email</p>
  <input
    pInputText
    class="w-full"
    [class.input-danger]="
      form.get('email')?.invalid && form.get('email')?.touched
    "
    placeholder="example@example.com"
    formControlName="email" />
  <app-error-message
    [control]="form.get('email')"
    label="Email"></app-error-message>
  <p class="text-base font-normal">Password</p>
  <input
    pInputText
    class="w-full"
    type="password"
    [class.input-danger]="
      form.get('password')?.invalid && form.get('password')?.touched
    "
    formControlName="password"
    placeholder="Password" />
  <app-error-message
    [control]="form.get('password')"
    label="Password"></app-error-message>
</form>
<p class="text-base font-normal text-center mb-4">
  <a class="text-blue-600 underline" href="/forgot-password"
    >Forgot Password?</a
  >
</p>
<app-action-button
  [disabled]="!this.form.valid"
  [actionInProgress]="processing$()"
  (actionClicked)="login()"
  label="Sign In"></app-action-button>
<p class="text-base font-normal text-center pt-1 !mt-1">
  Don't have an account?
  <a class="text-blue-600 underline" href="/demo">Request a Demo</a>
</p>
