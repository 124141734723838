import { MenuItem } from 'primeng/api';

export const SuperAdminMenu: MenuItem[] = [
  {
    label: 'Dashboard',
    icon: 'pi pi-th-large',
    routerLink: ['/dashboards/analytical'],
  },
  {
    label: 'Organizations',
    icon: 'pi pi-building-columns',
    routerLink: ['/organizations/list'],
  },
  {
    label: 'Demo Requests',
    icon: 'pi pi-info-circle',
    routerLink: ['/organizations/demo-requests'],
  },
  {
    label: 'Admin Users',
    icon: 'pi pi-user-plus',
    routerLink: ['/users/admin'],
  },
  {
    label: 'Settings',
    icon: 'pi pi-cog',
    routerLink: ['/settings'],
  },
];
export const OrganizationMenu: MenuItem[] = [
  {
    label: 'Dashboard',
    icon: 'pi pi-th-large',
    routerLink: ['/dashboards/analytical'],
    slugs: ['tracking-dashboard', 'recall-dashboard', 'heatmaps-dashboard'],
  },
  {
    label: 'Users',
    icon: 'pi pi-users',
    routerLink: ['/users/list'],
    slugs: ['list-user'],
  },
  {
    label: 'Vendors',
    icon: 'pi pi-warehouse',
    routerLink: ['/vendors/list'],
    slugs: ['list-vendor'],
  },
  {
    label: 'Products',
    icon: 'pi pi-shopping-bag',
    routerLink: ['/products/list'],
    slugs: ['list-product'],
  },
  {
    label: 'QR Orders',
    icon: 'pi pi-qrcode',
    routerLink: ['/qr/qr-orders/list'],
    slugs: ['list-qr-order'],
  },
  {
    label: 'Product Item Data',
    icon: 'pi pi-database',
    routerLink: ['/products/data'],
    slugs: ['list-product-data'],
  },
  {
    label: 'Settings',
    icon: 'pi pi-cog',
    routerLink: ['/settings'],
    slugs: ['list-cte-kde', 'list-role'],
  },
];

export const HeaderMenu: MenuItem[] = [
  {
    label: 'Settings',
    icon: 'pi pi-cog',
  },
  {
    label: 'Profile',
    icon: 'pi pi-user',
  },
  {
    separator: true,
  },
  {
    label: 'Logout',
    icon: 'pi pi-sign-out',
    command: () => {
      localStorage.clear();
      window.location.href = '/';
    },
  },
];
