import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/utilities/notification.service';
import { VendorStateModel } from './vendors.model';
import { VendorService } from './vendors.service';
import {
  CreateVendor,
  DeleteVendor,
  GetAllVendors,
  GetVendorsList,
  UpdateVendor,
} from './vendors.actions';

@State<VendorStateModel>({
  name: 'Vendor',
  defaults: {
    isProcessing: false,
    isListRefreshing: false,
    vendors: [],
    allVendors: [],
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class VendorState {
  constructor(
    private vendorService: VendorService,
    private notificationService: NotificationService
  ) {}

  @Action(GetVendorsList)
  getVendorList(
    { patchState }: StateContext<VendorStateModel>,
    action: GetVendorsList
  ) {
    patchState({ isProcessing: true });
    return this.vendorService.getVendors(action.payload).pipe(
      tap(async res => {
        patchState({
          vendors: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetAllVendors)
  getAllVendors({ patchState }: StateContext<VendorStateModel>) {
    return this.vendorService
      .getVendors({
        first: 0,
        rows: 10000,
        sortBy: 'name',
        sortOrder: 'ASC',
        filters: '',
        search: '',
      })
      .pipe(
        tap(async res => {
          patchState({
            allVendors: res.payload,
          });
        }),
        catchError(async error => {
          return throwError(() => error);
        })
      );
  }

  @Action(CreateVendor)
  createVendor(
    { patchState }: StateContext<VendorStateModel>,
    action: CreateVendor
  ) {
    patchState({ isProcessing: true });
    return this.vendorService.createVendor(action.payload).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        this.notificationService.openErrorToast(error.error.error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteVendor)
  deleteVendor(
    { patchState }: StateContext<VendorStateModel>,
    action: DeleteVendor
  ) {
    patchState({ isProcessing: true });
    return this.vendorService.deleteVendor(action.id).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Vendor deleted successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateVendor)
  updateVendor(
    { patchState }: StateContext<VendorStateModel>,
    action: UpdateVendor
  ) {
    patchState({ isProcessing: true });
    return this.vendorService.updateVendor(action.payload, action.id).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Vendor updated successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }
}
