import { Component, HostListener } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { Store } from '@ngxs/store';
import { OrganizationTypes } from '../../../../constants';
import { DemoRequest } from '../../../../store/users-store/user.actions';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { ActionButtonComponent } from '../../shared-components/action-button/action-button.component';
import { Country } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { StepperModule } from 'primeng/stepper';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { skipWhile, take } from 'rxjs';

@Component({
  selector: 'app-new-demo-request',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    StepperModule,
    ActionButtonComponent,
    ErrorMessageComponent,
    InputGroupModule,
    InputGroupAddonModule,
  ],
  templateUrl: './new-demo-request.component.html',
  styleUrl: './new-demo-request.component.scss',
})
export class NewDemoRequestComponent {
  organizationForm: FormGroup;
  personalForm: FormGroup;
  countries: Country[] = [];
  organizationTypes = OrganizationTypes;
  processing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );
  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.organizationForm = this.fb.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(15),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      countryCode: ['', [Validators.required]],
      address: ['', [Validators.required]],
    });
    this.personalForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
    this.store
      .select(GeneralStateSelectors.getCountries)
      .pipe(skipWhile(res => !res || res.length === 0))
      .pipe(take(1))
      .subscribe(res => {
        this.countries = res;
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.requestDemo();
    }
  }

  requestDemo() {
    if (this.organizationForm.invalid || this.personalForm.invalid) return;
    const trimmedValue = this.organizationForm.get('name')?.value?.trim();
    this.organizationForm.get('name')?.setValue(trimmedValue);
    if (!trimmedValue) {
      return;
    }
    this.store.dispatch(
      new DemoRequest({
        organizationName: this.organizationForm.get('name')?.value,
        type: this.organizationForm.get('type')?.value,
        email: this.organizationForm.get('email')?.value,
        phone: `${this.organizationForm.get('countryCode')?.value.dialCode} ${this.organizationForm.get('phone')?.value}`,
        countryId: this.organizationForm.get('countryCode')?.value.id,
        businessAddress: this.organizationForm.get('address')?.value,
        personalFirstName: this.personalForm.get('firstName')?.value,
        personalLastName: this.personalForm.get('lastName')?.value,
      })
    );
  }
}
