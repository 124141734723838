import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import LogRocket from 'logrocket';
import { HeaderComponent } from './layouts/header/header.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { NgClass } from '@angular/common';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../store/users-store/user.selectors';
import { AUTH_ROUTES } from '../constants';
import { AppStartAction } from '../store/general-store/general.actions';

LogRocket.init('d6wj4w/regenesis');

LogRocket.identify('App Started');

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidebarComponent, NgClass],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'regenesis';
  isSidebarExpanded = false;
  currUser = this.store.selectSignal(UserStateSelectors.getUser);

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.store.dispatch(new AppStartAction());
  }

  toggleSidebar() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  notAnAuthRoute() {
    return !AUTH_ROUTES.includes(this.router.url.split('?')[0]);
  }
}
