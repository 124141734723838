<div class="w-full p-8">
  <p-confirmDialog></p-confirmDialog>
  <app-right-form-base
    [isVisible]="sidebar.visible"
    (sidebarHidden)="sidebar.visible = false"
    [title]="sidebar.title">
    <app-invite-vendor-user-form
      [type]="sidebar.type"
      (actionSuccess)="
        this.invitationListComponent.getInvitationList();
        sidebar.visible = false
      ">
    </app-invite-vendor-user-form>
  </app-right-form-base>
  <div class="flex justify-between items-center">
    <h2>{{ pageTitle }}</h2>
    <div class="d-flex">
      <p-button
        appAccess="add-user"
        icon="pi pi-plus"
        label="Invite New User"
        (onClick)="getUpdatedData()" />
    </div>
  </div>

  <p-tabView>
    <p-tabPanel header="Active">
      <app-active-user-list></app-active-user-list>
    </p-tabPanel>
    <p-tabPanel header="Invited">
      <app-invitation-list></app-invitation-list>
    </p-tabPanel>
  </p-tabView>
</div>
