import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'textTransform',
  standalone: true,
})
export class TextTransformPipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value === 'boolean' || typeof value === 'number') {
      return value;
    }
    if (!value || value === '') {
      return value;
    }
    if (this.isValidDate(value)) {
      return format(value, 'MM/dd/yyyy hh:mm a');
    }
    if (value?.includes('_') || this.isAllCaps(value)) {
      return this.formatString(value);
    }
    return value;
  }

  isValidDate(value: string) {
    const datePattern =
      /^\d{4}-\d{2}-\d{2}$|^\d{2}\/\d{2}\/\d{4}$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;
    if (!datePattern.test(value)) {
      return false;
    }
    const date = new Date(value);
    return date instanceof Date && !isNaN(date.getTime());
  }

  formatString(value: string) {
    return value
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, match => match.toUpperCase());
  }

  isAllCaps(value: string) {
    return value === value.toUpperCase();
  }
}
