import { Component, EventEmitter, Output, Signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { environment } from '../../../environments/environment';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MenuItem } from 'primeng/api';
import { HeaderMenu } from '../../data/menu.data';
import { User } from '../../../store/users-store/user.model';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../../store/users-store/user.selectors';
import { ToolbarModule } from 'primeng/toolbar';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MenubarModule, ButtonModule, TieredMenuModule, ToolbarModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Output() toggleSidebar = new EventEmitter<void>();
  bucketUrl = environment.bucketUrl;
  items: MenuItem[] = HeaderMenu;

  user?: User;
  orgLogoUrl$: Signal<string | undefined> = this.store.selectSignal(
    UserStateSelectors.getOrganizationLogo
  );
  constructor(private store: Store) {
    this.user = this.store.selectSnapshot(UserStateSelectors.getUser);
  }

  onToggleSidebar() {
    this.toggleSidebar.emit();
  }
}
