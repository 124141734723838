<div class="card image-upload-card">
  <p-fileUpload
    #fileUpload
    name="demo[]"
    customUpload="true"
    accept="image/*"
    maxFileSize="1000000"
    (uploadHandler)="onCustomUpload($event)"
    [auto]="true"
    class="file-upload-container"
    [multiple]="!singleMode ? true : false"
    [fileLimit]="!singleMode ? 5 : 1">
    <ng-template
      pTemplate="header"
      let-files
      let-chooseCallback="chooseCallback"
      let-clearCallback="clearCallback"
      let-uploadCallback="uploadCallback">
      <div
        class="flex flex-wrap justify-content-between align-items-center gap-2">
        <div class="flex gap-2">
          <p-button
            (click)="choose($event, chooseCallback)"
            icon="pi pi-images"
            [rounded]="true"
            [outlined]="true"
            [disabled]="
              singleMode ? files.length === 1 : files.length > 5
            "></p-button>
        </div>
      </div>
    </ng-template>

    <ng-template
      pTemplate="content"
      let-files
      let-removeFileCallback="removeFileCallback">
      <div class="grid h-full h-100">
        <div class="col-12 block align-items-center justify-content-center">
          <div class="text-center">
            <i
              class="pi pi-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
            <p class="mt-4 mb-0">Drag and drop files here to upload.</p>
            <p class="mt-4 mb-0">File size 1mb.</p>
            <p class="mt-4 mb-0">Dimension 100 x 100.</p>
            <p class="mt-4 mb-0">File type all images types.</p>
          </div>
        </div>
      </div>
      @if (
        uploadedFiles$()[fileType] && uploadedFiles$()[fileType].length > 0
      ) {
        <div class="flex flex-wrap p-0 sm:p-5 gap-5 image-content">
          <div
            *ngFor="let file of uploadedFiles$()[fileType]; let i = index"
            class="image-wrapper relative flex align-items-center justify-content-center">
            <div class="frame-container">
              @if (!file.uploading) {
                <img
                  role="presentation"
                  [alt]="file.name"
                  [src]="file.objectURL"
                  class="image-preview" />
              }
              @if (file.uploading) {
                <p-progressSpinner class="loader"></p-progressSpinner>
              }
              <p-button
                icon="pi pi-times"
                (click)="
                  removeUploadedFileCallback(
                    $event,
                    file,
                    removeFileCallback,
                    i
                  )
                "
                class="remove-button"
                [rounded]="true"
                [outlined]="true"
                severity="danger"></p-button>
            </div>
          </div>
        </div>
      }
    </ng-template>

    <ng-template pTemplate="file"></ng-template>
  </p-fileUpload>
</div>
