/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  OnChanges,
  Signal,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AddProductFormComponent } from '../../form-components/add-product-form/add-product-form.component';
import { AccordionModule } from 'primeng/accordion';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { OrderListModule } from 'primeng/orderlist';
import {
  Product,
  ProductTemplate,
  UpdateProductTemplateParams,
} from '../../../../store/products-store/products.model';
import { ButtonModule } from 'primeng/button';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep } from 'lodash';
import { ActionButtonComponent } from '../action-button/action-button.component';
import { Store } from '@ngxs/store';
import { UpdateProductTemplate } from '../../../../store/products-store/products.actions';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';

const sections = [
  {
    label: 'Origin',
    content: '',
    sort: 1,
    key: 'originInfo',
    disabled: false,
    rawValue: {},
  },
  {
    label: 'Nutritional Information',
    content: '',
    sort: 2,
    key: 'nutritionalInfo',
    disabled: false,
    rawValue: {},
  },
  {
    label: 'Safety and Quality Assurance',
    content: '',
    sort: 3,
    key: 'safetyAndQA',
    disabled: false,
    rawValue: {},
  },
  {
    label: 'Traceability',
    content: '',
    sort: 4,
    key: 'traceabilityInfo',
    disabled: false,
    rawValue: {},
  },
  {
    label: 'Usage Instructions',
    content: '',
    sort: 5,
    key: 'usageInstructions',
    disabled: false,
    rawValue: {},
  },
];
@Component({
  selector: 'app-product-scan-details',
  standalone: true,
  imports: [
    AddProductFormComponent,
    AccordionModule,
    ErrorMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    IconFieldModule,
    InputIconModule,
    ToggleButtonModule,
    OrderListModule,
    ButtonModule,
    ActionButtonComponent,
  ],
  templateUrl: './product-scan-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrl: './product-scan-details.component.scss',
})
export class ProductScanDetailsComponent implements OnChanges {
  form: FormGroup;
  originInfoForm: FormGroup;
  nutritionalInfoForm: FormGroup;
  safetyAndQualityForm: FormGroup;
  traceabilityForm: FormGroup;
  usageInstructionsForm: FormGroup;
  socialImagesForm: FormGroup;
  productImage = '';
  productTemplate: ProductTemplate | undefined = undefined;
  isProcessing$: Signal<boolean> = this.store.selectSignal(
    ProductStateSelectors.isProcessing
  );

  sections = sections;

  @Input() product: Product | undefined = undefined;

  get originInfo(): FormArray {
    return this.originInfoForm.get('originInfo') as FormArray;
  }

  get nutritionalInfo(): FormArray {
    return this.nutritionalInfoForm.get('nutritionalInfo') as FormArray;
  }

  get safetyInfo(): FormArray {
    return this.safetyAndQualityForm.get('safetyInfo') as FormArray;
  }

  get traceabilityInfo(): FormArray {
    return this.traceabilityForm.get('traceabilityInfo') as FormArray;
  }

  get usageInfo(): FormArray {
    return this.usageInstructionsForm.get('usageInfo') as FormArray;
  }

  get socialImages(): FormArray {
    return this.socialImagesForm.get('socialImages') as FormArray;
  }

  addNewDataRow(type: number): void {
    switch (type) {
      case 1:
        this.originInfo.push(this.addNewKeyValuePair());
        break;
      case 2:
        this.nutritionalInfo.push(this.addNewKeyValuePair());
        break;
      case 3:
        this.safetyInfo.push(this.addNewKeyValuePair());
        break;
      case 4:
        this.traceabilityInfo.push(this.addNewKeyValuePair());
        break;
      case 5:
        this.usageInfo.push(this.addNewKeyValuePair());
        break;
      case 6:
        this.socialImages.push(this.addNewKeyValuePair());
        break;
      default:
        break;
    }
  }

  addNewKeyValuePair(key = '', value = ''): FormGroup {
    return this.fb.group({
      key: [key, Validators.required],
      value: [value, Validators.required],
    });
  }

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });

    this.originInfoForm = this.fb.group({
      isEnabled: [false],
      originInfo: this.fb.array([]),
    });

    this.nutritionalInfoForm = this.fb.group({
      isEnabled: [false],
      nutritionalInfo: this.fb.array([]),
    });

    this.safetyAndQualityForm = this.fb.group({
      isEnabled: [false],
      safetyInfo: this.fb.array([]),
    });

    this.traceabilityForm = this.fb.group({
      isEnabled: [false],
      traceabilityInfo: this.fb.array([]),
    });

    this.usageInstructionsForm = this.fb.group({
      isEnabled: [false],
      usageInfo: this.fb.array([]),
    });

    this.socialImagesForm = this.fb.group({
      socialImages: this.fb.array([]),
    });

    this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.getSections();
    });
    this.originInfoForm.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.getSections();
      });
    this.socialImagesForm.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.getSections();
      });
    this.traceabilityForm.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.getSections();
      });
    this.nutritionalInfoForm.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.getSections();
      });
    this.safetyAndQualityForm.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.getSections();
      });
    this.usageInstructionsForm.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.getSections();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product'].currentValue?.productDesignTemplates?.length > 0) {
      this.productTemplate =
        changes['product'].currentValue?.productDesignTemplates[0];
      const productTemplate =
        changes['product'].currentValue.productDesignTemplates[0];
      for (const key in productTemplate) {
        if (
          typeof productTemplate[key] === 'string' &&
          productTemplate[key].includes('\\')
        ) {
          try {
            productTemplate[key] = JSON.parse(JSON.parse(productTemplate[key]));
          } catch (error) {
            console.error(`Error parsing key "${key}":`, error);
          }
        }
      }
      this.form.patchValue({
        name: changes['product'].currentValue.productDesignTemplates[0]
          .productName,
        description:
          changes['product'].currentValue.productDesignTemplates[0].description,
      });

      //Social Images
      Object.entries(
        changes['product'].currentValue.productDesignTemplates[0].productLinks
      )
        .filter(([key, value]) => key !== '' && value !== '')
        .map(([key, value]) => ({ key, value }))
        .forEach(({ key, value }) => {
          this.socialImages.push(this.addNewKeyValuePair(key, value as string));
        });
      //Origin Info
      this.originInfoForm.patchValue({
        isEnabled:
          changes['product'].currentValue.productDesignTemplates[0].originInfo
            .isEnabled,
      });
      sections[0].sort =
        changes[
          'product'
        ].currentValue.productDesignTemplates[0].originInfo.order;
      Object.entries(
        changes['product'].currentValue.productDesignTemplates[0].originInfo
          .data
      )
        .filter(([key, value]) => key !== '' && value !== '')
        .map(([key, value]) => ({ key, value }))
        .forEach(({ key, value }) => {
          this.originInfo.push(this.addNewKeyValuePair(key, value as string));
        });

      //Nutriotional Info
      this.nutritionalInfoForm.patchValue({
        isEnabled:
          changes['product'].currentValue.productDesignTemplates[0]
            .nutritionalInfo.isEnabled,
      });
      sections[1].sort =
        changes[
          'product'
        ].currentValue.productDesignTemplates[0].nutritionalInfo.order;
      Object.entries(
        changes['product'].currentValue.productDesignTemplates[0]
          .nutritionalInfo.data
      )
        .filter(([key, value]) => key !== '' && value !== '')
        .map(([key, value]) => ({ key, value }))
        .forEach(({ key, value }) => {
          this.nutritionalInfo.push(
            this.addNewKeyValuePair(key, value as string)
          );
        });

      //safetyAndQA Info
      this.safetyAndQualityForm.patchValue({
        isEnabled:
          changes['product'].currentValue.productDesignTemplates[0].safetyAndQA
            .isEnabled,
      });
      sections[2].sort =
        changes[
          'product'
        ].currentValue.productDesignTemplates[0].safetyAndQA.order;
      Object.entries(
        changes['product'].currentValue.productDesignTemplates[0].safetyAndQA
          .data
      )
        .filter(([key, value]) => key !== '' && value !== '')
        .map(([key, value]) => ({ key, value }))
        .forEach(({ key, value }) => {
          this.safetyInfo.push(this.addNewKeyValuePair(key, value as string));
        });

      //safetyAndQA Info
      this.safetyAndQualityForm.patchValue({
        isEnabled:
          changes['product'].currentValue.productDesignTemplates[0].safetyAndQA
            .isEnabled,
      });
      sections[3].sort =
        changes[
          'product'
        ].currentValue.productDesignTemplates[0].safetyAndQA.order;
      Object.entries(
        changes['product'].currentValue.productDesignTemplates[0].safetyAndQA
          .data
      )
        .filter(([key, value]) => key !== '' && value !== '')
        .map(([key, value]) => ({ key, value }))
        .forEach(({ key, value }) => {
          this.safetyInfo.push(this.addNewKeyValuePair(key, value as string));
        });

      //Usage Info
      this.usageInstructionsForm.patchValue({
        isEnabled:
          changes['product'].currentValue.productDesignTemplates[0]
            .usageInstructions.isEnabled,
      });
      sections[4].sort =
        changes[
          'product'
        ].currentValue.productDesignTemplates[0].usageInstructions.order;
      Object.entries(
        changes['product'].currentValue.productDesignTemplates[0]
          .usageInstructions.data
      )
        .filter(([key, value]) => key !== '' && value !== '')
        .map(([key, value]) => ({ key, value }))
        .forEach(({ key, value }) => {
          this.usageInfo.push(this.addNewKeyValuePair(key, value as string));
        });

      this.productImage =
        changes['product'].currentValue.productDesignTemplates[0].productImage;

      this.getSections();
    }
  }

  disbaleClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
  removeOriginInfo(index: number): void {
    this.originInfo.removeAt(index);
  }
  removeNutritionalInfo(index: number): void {
    this.nutritionalInfo.removeAt(index);
  }

  removeSafetyInfo(index: number): void {
    this.safetyInfo.removeAt(index);
  }
  removeTraceabilityInfo(index: number): void {
    this.traceabilityInfo.removeAt(index);
  }

  removeUsageInfo(index: number): void {
    this.usageInfo.removeAt(index);
  }

  removeSocialLink(index: number): void {
    this.socialImages.removeAt(index);
  }

  getSections() {
    sections[0].content = ``;
    sections[1].content = ``;
    sections[2].content = ``;
    sections[3].content = ``;
    sections[4].content = ``;
    sections[0].rawValue = {};
    sections[1].rawValue = {};
    sections[2].rawValue = {};
    sections[3].rawValue = {};
    sections[4].rawValue = {};

    if (this.originInfoForm.value.isEnabled) {
      sections[0].rawValue = this.originInfoForm.value.originInfo.reduce(
        (obj: any, item: any) => {
          obj[item.key] = item.value;
          return obj;
        },
        {}
      );
      for (const originInfo of this.originInfoForm.value.originInfo) {
        if (originInfo.key || originInfo.value) {
          sections[0].content += `<div class="text-sm">${originInfo.key}: ${originInfo.value}</div>`;
        }
      }
    }
    if (this.nutritionalInfoForm.value.isEnabled) {
      sections[1].rawValue =
        this.nutritionalInfoForm.value.nutritionalInfo.reduce(
          (obj: any, item: any) => {
            obj[item.key] = item.value;
            return obj;
          },
          {}
        );
      sections[1].content = '<ul>';
      for (const nutritionalInfo of this.nutritionalInfoForm.value
        .nutritionalInfo) {
        if (nutritionalInfo.key || nutritionalInfo.value) {
          sections[1].content += `<li class="text-sm">${nutritionalInfo.key}: ${nutritionalInfo.value}</li>`;
        }
      }
      sections[1].content += `</ul>`;
    }
    if (this.safetyAndQualityForm.value.isEnabled) {
      sections[2].rawValue = this.safetyAndQualityForm.value.safetyInfo.reduce(
        (obj: any, item: any) => {
          obj[item.key] = item.value;
          return obj;
        },
        {}
      );
      for (const safetyAndQuality of this.safetyAndQualityForm.value
        .safetyInfo) {
        if (safetyAndQuality.key || safetyAndQuality.value) {
          sections[2].content += `<div class="text-sm">${safetyAndQuality.key}: ${safetyAndQuality.value}</div>`;
        }
      }
    }
    if (this.traceabilityForm.value.isEnabled) {
      sections[3].rawValue =
        this.traceabilityForm.value.traceabilityInfo.reduce(
          (obj: any, item: any) => {
            obj[item.key] = item.value;
            return obj;
          },
          {}
        );
      for (const traceability of this.traceabilityForm.value.traceabilityInfo) {
        if (traceability.key || traceability.value) {
          sections[3].content += `<div class="text-sm">${traceability.key}: ${traceability.value}</div>`;
        }
      }
    }
    if (this.usageInstructionsForm.value.isEnabled) {
      sections[4].rawValue = this.usageInstructionsForm.value.usageInfo.reduce(
        (obj: any, item: any) => {
          obj[item.key] = item.value;
          return obj;
        },
        {}
      );
      for (const usageInstructions of this.usageInstructionsForm.value
        .usageInfo) {
        if (usageInstructions.key || usageInstructions.value) {
          sections[4].content += `<div class="text-sm">${usageInstructions.key}: ${usageInstructions.value}</div>`;
        }
      }
    }

    this.sections = cloneDeep(sections).sort((a, b) => a.sort - b.sort);
  }

  reOrderedSection() {
    let order = 0;
    for (const section of this.sections) {
      section.sort = order;
      order++;
    }
  }

  saveTemplate() {
    const requestObj = { ...this.productTemplate };
    delete requestObj.id;
    delete requestObj.actions;
    delete requestObj.createdAt;
    delete requestObj.deletedAt;
    delete requestObj.updatedAt;
    requestObj.productLinks = this.socialImagesForm.value.socialImages.reduce(
      (obj: any, item: any) => {
        obj[item.key] = item.value;
        return obj;
      },
      {}
    );
    let order = 0;
    for (const section of this.sections) {
      if (requestObj[section.key]) {
        requestObj[section.key].data = section.rawValue;
        requestObj[section.key].order = order;
        order++;
      }
    }
    if (this.productTemplate?.id)
      this.store.dispatch(
        new UpdateProductTemplate(
          this.productTemplate.id,
          requestObj as UpdateProductTemplateParams
        )
      );
  }
}
