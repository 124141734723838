import { Action, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/utilities/notification.service';
import { ProductStateModel } from './products.model';
import { ProductService } from './products.service';
import {
  AddProduct,
  AddProductKDE,
  AddProductTemplateComponent,
  AddProductTemplateModule,
  BulkUpdateProductTemplateModules,
  ClearProductData,
  DeleteProduct,
  DeleteProductTemplateComponent,
  DeleteProductTemplateModule,
  GetAllProducts,
  GetProduct,
  GetProductItemDataList,
  GetProductList,
  ResetHeaders,
  SearchProducts,
  UpdateProduct,
  UpdateProductKDEs,
  UpdateProductQrSettings,
  UpdateProductTemplate,
  UpdateProductTemplateComponent,
  DeleteProductKDE,
  BulkUpdateProductTemplateComponents,
} from './products.actions';
import { Router } from '@angular/router';

@State<ProductStateModel>({
  name: 'Product',
  defaults: {
    isProcessing: false,
    isListRefreshing: false,
    products: [],
    productItemData: [],
    allProducts: [],
    searchedProducts: [],
    product: null,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class ProductState {
  constructor(
    private productService: ProductService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  @Action(GetProductList)
  getProductList(
    { patchState }: StateContext<ProductStateModel>,
    action: GetProductList
  ) {
    patchState({ isProcessing: true });
    return this.productService.getProducts(action.payload).pipe(
      tap(async res => {
        patchState({
          products: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(SearchProducts)
  searchProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: SearchProducts
  ) {
    patchState({ isProcessing: true });
    return this.productService.getProducts(action.payload).pipe(
      tap(async res => {
        patchState({
          searchedProducts: res.payload,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetProductItemDataList)
  getProductItemData(
    { patchState }: StateContext<ProductStateModel>,
    action: GetProductItemDataList
  ) {
    patchState({ isProcessing: true });
    return this.productService.getProductItemData(action.payload).pipe(
      tap(async res => {
        patchState({
          productItemData: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteProduct)
  deleteProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: DeleteProduct
  ) {
    patchState({ isProcessing: true });
    return this.productService.deleteProduct(action.id).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Product deleted successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetAllProducts)
  getAllProducts({ patchState }: StateContext<ProductStateModel>) {
    return this.productService
      .getProducts({
        first: 0,
        rows: 10000,
        sortBy: 'productName',
        sortOrder: 'ASC',
        filters: '',
        search: '',
      })
      .pipe(
        tap(async res => {
          patchState({
            allProducts: res.payload,
          });
        }),
        catchError(async error => {
          return throwError(() => error);
        })
      );
  }

  @Action(AddProduct)
  addProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: AddProduct
  ) {
    patchState({ isProcessing: true });
    return this.productService.addProduct(action.payload).pipe(
      tap(async res => {
        const sortedArr = res.payload.productCtes.sort(
          (a, b) => a.order - b.order
        );
        for (const element of sortedArr) {
          element.productKdes = element.productKdes.sort(
            (a, b) => a.order - b.order
          );
        }
        patchState({
          isProcessing: false,
          product: res.payload,
        });
        this.notificationService.openSuccessToast(
          'Product created successfully'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        if (error.error.error.message === 'Validation error') {
          error.error.error.details.forEach((detail: any) => {
            this.notificationService.openErrorToast(detail.message);
          });
        } else {
          this.notificationService.openErrorToast(error.error.error.message);
        }

        return throwError(() => error);
      })
    );
  }

  @Action(GetProduct)
  getProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: GetProduct
  ) {
    patchState({ isProcessing: true });
    return this.productService.getProduct(action.payload).pipe(
      tap(async res => {
        const sortedArr = res.payload.productCtes.sort(
          (a, b) => a.order - b.order
        );
        for (const element of sortedArr) {
          element.productKdes = element.productKdes.sort(
            (a, b) => a.order - b.order
          );
        }
        patchState({
          product: res.payload,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateProduct)
  updateProduct(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProduct
  ) {
    patchState({ isProcessing: true });
    return this.productService.updateProduct(action.id, action.payload).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Product updated successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        if (error.error.error.message === 'Validation error') {
          error.error.error.details.forEach((detail: any) => {
            this.notificationService.openErrorToast(detail.message);
          });
        } else {
          this.notificationService.openErrorToast(error.error.error.message);
        }
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateProductKDEs)
  updateProductKdes(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProductKDEs
  ) {
    return this.productService.updateProductKDEs(action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'Product KDEs order updated successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(AddProductKDE)
  addProductKde(
    { getState, patchState }: StateContext<ProductStateModel>,
    action: AddProductKDE
  ) {
    const state = getState();
    const product = state.product;
    const index = product!.productCtes.findIndex(
      cte => cte.id === action.cte.id
    );
    product!.productCtes[index] = action.cte;
    const kdes = product!.productCtes[index].productKdes;
    product!.productCtes[index].productKdes = [...kdes];
    patchState({
      product,
    });
  }

  @Action(DeleteProductKDE)
  deleteProductKDE(
    { getState, patchState }: StateContext<ProductStateModel>,
    action: DeleteProductKDE
  ) {
    const state = getState();
    const product = state.product;

    const cteIndex = product!.productCtes.findIndex(
      cte => cte.id === action.cte.id
    );

    if (cteIndex !== -1) {
      const updatedKdes = product!.productCtes[cteIndex].productKdes.filter(
        kde => kde.id !== action.kdeID
      );

      product!.productCtes[cteIndex].productKdes = updatedKdes;
      patchState({
        product,
      });
    }
  }

  @Action(UpdateProductQrSettings)
  updateProductQrSettings(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProductQrSettings
  ) {
    return this.productService
      .updateProductQrSettings(action.id, action.payload)
      .pipe(
        tap(async () => {
          this.notificationService.openSuccessToast(
            'Product QR Settings updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(UpdateProductTemplate)
  updateProductTemplate(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProductTemplate
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .updateProductTemplate(action.id, action.payload)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
          this.notificationService.openSuccessToast(
            'Product Template updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(AddProductTemplateModule)
  addProductTemplateModule(
    { patchState }: StateContext<ProductStateModel>,
    action: AddProductTemplateModule
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .addProductTemplateModule(action.productId, action.id)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
          this.notificationService.openSuccessToast(
            'Product Template Module added successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(BulkUpdateProductTemplateModules)
  bulkUpdateProductTemplateModules(
    { patchState }: StateContext<ProductStateModel>,
    action: BulkUpdateProductTemplateModules
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .bulkUpdateProductTemplateModules(action.payload)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
          this.notificationService.openSuccessToast(
            'Product Template Modules updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(BulkUpdateProductTemplateComponents)
  bulkUpdateProductTemplateComponents(
    { patchState }: StateContext<ProductStateModel>,
    action: BulkUpdateProductTemplateComponents
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .bulkUpdateProductTemplateComponents(action.payload)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
          this.notificationService.openSuccessToast(
            'Product Template Components updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(DeleteProductTemplateModule)
  deleteProductTemplateModule(
    { patchState }: StateContext<ProductStateModel>,
    action: DeleteProductTemplateModule
  ) {
    patchState({ isProcessing: true });
    return this.productService.deleteProductTemplateModule(action.id).pipe(
      tap(async () => {
        patchState({ isProcessing: false });
        this.notificationService.openSuccessToast(
          'Product Template Module deleted successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteProductTemplateComponent)
  deleteProductTemplateComponent(
    { patchState }: StateContext<ProductStateModel>,
    action: DeleteProductTemplateComponent
  ) {
    patchState({ isProcessing: true });
    return this.productService.deleteProductTemplateComponent(action.id).pipe(
      tap(async () => {
        patchState({ isProcessing: false });
        this.notificationService.openSuccessToast(
          'Product Template Component deleted successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateProductTemplateComponent)
  updateProductTemplateComponent(
    { patchState }: StateContext<ProductStateModel>,
    action: UpdateProductTemplateComponent
  ) {
    patchState({ isProcessing: true });
    return this.productService
      .updateProductTemplateComponent(action.id, action.payload)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
          this.notificationService.openSuccessToast(
            'Product Template Component updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(AddProductTemplateComponent)
  addProductTemplateComponent(
    { patchState }: StateContext<ProductStateModel>,
    action: AddProductTemplateComponent
  ) {
    patchState({ isProcessing: true });
    return this.productService.addProductTemplateComponent(action.payload).pipe(
      tap(async () => {
        patchState({ isProcessing: false });
        this.notificationService.openSuccessToast(
          'Product Template Component added successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(ResetHeaders)
  resetHeaders({ patchState }: StateContext<ProductStateModel>) {
    patchState({ headers: [] });
  }

  @Action(ClearProductData)
  clearProductData({ patchState }: StateContext<ProductStateModel>) {
    patchState({ product: null });
  }
}
