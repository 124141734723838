import { Component, OnInit, OnDestroy, Signal, effect } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_worldHigh from '@amcharts/amcharts5-geodata/worldHigh';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ActionButtonComponent } from '../../../components/shared-components/action-button/action-button.component';
import { ErrorMessageComponent } from '../../../components/shared-components/error-message/error-message.component';
import { Store } from '@ngxs/store';
import { GetProductList } from '../../../../store/products-store/products.actions';
import { Product } from '../../../../store/products-store/products.model';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import { GetCteStatistics } from '../../../../store/general-store/general.actions';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { CteMapStatistics } from '../../../../store/general-store/general.model';
import { filter, Observable, take } from 'rxjs';
import { ActivityFeedComponent } from '../../../components/shared-components/activity-feed/activity-feed.component';

interface ProductDataItem {
  latitude: number;
  longitude: number;
  name: string;
  totalCount: number;
  color?: am5.Color; // Optional, assigned dynamically based on totalCount
}

@Component({
  selector: 'app-analytical-dashboard',
  templateUrl: './analytical-dashboard.component.html',
  styleUrls: ['./analytical-dashboard.component.scss'],
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ActionButtonComponent,
    ErrorMessageComponent,
    DropdownModule,
    ActivityFeedComponent,
  ],
  standalone: true,
})
export class AnalyticalDashboardComponent implements OnInit, OnDestroy {
  private root!: am5.Root;
  private chart!: am5map.MapChart;
  private bubbleSeries!: am5map.MapPointSeries;

  products: Observable<Product[]> | undefined;
  form: FormGroup;
  products$: Signal<Product[]> = this.store.selectSignal(
    ProductStateSelectors.getProducts
  );
  productData$: Signal<CteMapStatistics[]> = this.store.selectSignal(
    GeneralStateSelectors.getProductCteStats
  );

  productData: Observable<CteMapStatistics[]> | undefined;
  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      product: ['', [Validators.required]],
    });

    effect(
      () => {
        this.products = this.store.select(ProductStateSelectors.getProducts);
        this.productData = this.store.select(
          GeneralStateSelectors.getProductCteStats
        );
        this.products
          .pipe(
            filter(data => data && data.length > 0),
            take(1)
          )
          .subscribe(data => {
            this.store.dispatch(new GetCteStatistics(data[0].id));
            this.form.controls['product'].setValue(data[0]);
          });
        this.productData.subscribe(data => {
          this.setMapData(data);
        });
      },
      { allowSignalWrites: true }
    );
  }
  ngOnInit(): void {
    this.form.controls['product'].valueChanges.subscribe(value => {
      this.store.dispatch(new GetCteStatistics(value.id));
    });
    this.store.dispatch(
      new GetProductList({
        first: 0,
        rows: 100,
        sortBy: 'createdAt',
        sortOrder: 'DESC',
        search: '',
        filters: '',
      })
    );
    // Create root element for amCharts
    this.root = am5.Root.new('chartdiv');

    // Set themes
    this.root.setThemes([
      am5themes_Animated.new(this.root),
      am5themes_Dark.new(this.root),
    ]);

    // Create map chart
    this.chart = this.root.container.children.push(
      am5map.MapChart.new(this.root, {
        panX: 'rotateX',
        projection: am5map.geoNaturalEarth1(),
        paddingBottom: 20,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
      })
    );

    // Create polygon series for map background
    const polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.root, {
        geoJSON: am5geodata_worldHigh,
        exclude: ['AQ'], // Exclude Antarctica
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{cteName}',
      toggleKey: 'active',
      interactive: true,
    });

    // Add animated bubble series
    this.bubbleSeries = this.chart.series.push(
      am5map.MapPointSeries.new(this.root, {
        valueField: 'totalCount',
        calculateAggregates: true,
        latitudeField: 'latitude',
        longitudeField: 'longitude',
      })
    );

    this.bubbleSeries.bullets.push((root, _, dataItem) => {
      const productData = dataItem.dataContext as ProductDataItem;

      const container = am5.Container.new(root, {});

      const circle = container.children.push(
        am5.Circle.new(root, {
          radius: 20,
          fillOpacity: 0.7,
          fill: this.getBubbleColor(productData.totalCount), // Dynamic color based on totalCount
          cursorOverStyle: 'pointer',
          tooltipText: '{cteName}: [bold]{totalCount}[/] Items',
        })
      );

      const productLabel = container.children.push(
        am5.Label.new(root, {
          text: '{cteName}',
          paddingLeft: 5,
          populateText: true,
          fontWeight: 'bold',
          fontSize: 13,
          centerY: am5.p50,
          fill: am5.color(0x000000),
        })
      );

      container.children.push(
        am5.Label.new(root, {
          text: '{totalCount}',
          centerX: am5.p50,
          centerY: am5.p50,
          populateText: true,
          fontSize: 12,
          fill: am5.color(0xffffff),
        })
      );

      circle.on('radius', radius => {
        productLabel.set('x', radius);
      });

      // Adapt circle radius based on totalCount
      if (productData && typeof productData.totalCount === 'number') {
        const radius = 10 + productData.totalCount / 20;
        circle.set('radius', radius);
      }

      return am5.Bullet.new(root, {
        sprite: container,
        dynamic: true,
      });
    });
  }

  // Function to return color based on totalCount thresholds
  private getBubbleColor(totalCount: number): am5.Color {
    if (totalCount <= 10) {
      return am5.color(0x00ff00); // Safe (green)
    } else if (totalCount <= 50) {
      return am5.color(0xffff00); // Warning (yellow)
    } else {
      return am5.color(0xff0000); // Danger (red)
    }
  }
  setMapData(data: CteMapStatistics[]) {
    this.bubbleSeries.data.setAll(data);

    // Add zoom control
    this.chart.set('zoomControl', am5map.ZoomControl.new(this.root, {}));

    // Set background color
    this.root.interfaceColors.set('background', am5.color(0x000000));

    // Make stuff animate on load
    this.chart.appear(1000, 100);

    // Start updating data every 2 seconds
    // this.startDataUpdate();
  }
  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
