import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/utilities/notification.service';
import { RolesPermissionStateModel } from './roles-permission.model';
import { RolesPermissionService } from './roles-permission.service';
import {
  AddRoleProduct,
  CreateRole,
  DeleteRole,
  DeleteRoleProduct,
  GetRole,
  GetRoleList,
  UpdateRole,
  UpdateRoleCte,
  UpdateRolePermission,
  UpdateRolePermissions,
  UpdateRoleProduct,
} from './roles-permission.actions';
@State<RolesPermissionStateModel>({
  name: 'RolesPermission',
  defaults: {
    isProcessing: false,
    isListRefreshing: false,
    roles: [],
    role: null,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class RolesPermissionState {
  constructor(
    private rolesPermissionService: RolesPermissionService,
    private notificationService: NotificationService
  ) {}

  @Action(CreateRole)
  createRole({ patchState }: StateContext<RolesPermissionStateModel>) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService.createRole().pipe(
      tap(async res => {
        patchState({
          isProcessing: false,
          role: res.payload,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        this.notificationService.openErrorToast(error.error.error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateRole)
  updateRole(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: UpdateRole
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService
      .updateRole(action.id, action.payload)
      .pipe(
        tap(async _res => {
          patchState({
            isProcessing: false,
          });
          this.notificationService.openSuccessToast(
            'Role updated successfully'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false, isListRefreshing: false });
          this.notificationService.openErrorToast(error.error.error.message);
          return throwError(() => error);
        })
      );
  }

  @Action(UpdateRolePermission)
  updateRolePermission(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: UpdateRolePermission
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService
      .updateRolePermission(action.id, action.payload)
      .pipe(
        tap(async _res => {
          patchState({
            isProcessing: false,
          });
          this.notificationService.openSuccessToast(
            'Role Permission updated successfully'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false, isListRefreshing: false });
          this.notificationService.openErrorToast(error.error.error.message);
          return throwError(() => error);
        })
      );
  }

  @Action(UpdateRolePermissions)
  updateRolePermissions(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: UpdateRolePermissions
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService
      .updateRolePermissions(action.roleId, action.payload)
      .pipe(
        tap(async _res => {
          patchState({
            isProcessing: false,
          });
          this.notificationService.openSuccessToast(
            'Role Permission updated successfully'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false, isListRefreshing: false });
          this.notificationService.openErrorToast(error.error.error.message);
          return throwError(() => error);
        })
      );
  }

  @Action(UpdateRoleCte)
  updateRoleCte(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: UpdateRoleCte
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService
      .updateRoleCte(action.id, action.payload)
      .pipe(
        tap(async _res => {
          patchState({
            isProcessing: false,
          });
          this.notificationService.openSuccessToast(
            'Role CTE updated successfully'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false, isListRefreshing: false });
          this.notificationService.openErrorToast(error.error.error.message);
          return throwError(() => error);
        })
      );
  }

  @Action(UpdateRoleProduct)
  updateRoleProduct(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: UpdateRoleProduct
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService
      .updateRoleProduct(action.id, action.payload)
      .pipe(
        tap(async _res => {
          patchState({
            isProcessing: false,
          });
          this.notificationService.openSuccessToast(
            'Role Product updated successfully'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false, isListRefreshing: false });
          this.notificationService.openErrorToast(error.error.error.message);
          return throwError(() => error);
        })
      );
  }

  @Action(DeleteRoleProduct)
  deleteRoleProduct(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: DeleteRoleProduct
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService.deleteRoleProduct(action.id).pipe(
      tap(async _res => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Role Product deleted successfully'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        this.notificationService.openErrorToast(error.error.error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteRole)
  deleteRole(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: DeleteRole
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService.deleteRole(action.id).pipe(
      tap(async res => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast('Role deleted successfully');
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        this.notificationService.openErrorToast(error.error.error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(AddRoleProduct)
  addRoleProduct(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: AddRoleProduct
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService.addRoleProduct(action.payload).pipe(
      tap(async _res => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Product assigned to the role successfully'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        this.notificationService.openErrorToast(error.error.error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(GetRoleList)
  getRoleList(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: GetRoleList
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService.getRoles(action.payload).pipe(
      tap(async res => {
        patchState({
          roles: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetRole)
  getRole(
    { patchState }: StateContext<RolesPermissionStateModel>,
    action: GetRole
  ) {
    patchState({ isProcessing: true });
    return this.rolesPermissionService.getRole(action.id).pipe(
      tap(async res => {
        patchState({
          role: res.payload,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }
}
