<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <h2>{{ pageTitle }}</h2>
  </div>
  <p-card class="p-[0px]">
    <div class="d-flex">
      <img
        class="profile-image mr-[20px]"
        [src]="bucketUrl + '/images/avatar.png'"
        alt="Profile" />
      <h2>{{ user?.firstName }} {{ user?.lastName }}</h2>
    </div>
  </p-card>

  <p-tabView [(activeIndex)]="activeTab" (onChange)="onTabChange($event)">
    @for (tab of tabs; track tab) {
      <p-tabPanel [header]="tab.title"></p-tabPanel>
    }
  </p-tabView>
  <router-outlet></router-outlet>
</div>
