import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  NgxQrcodeStylingComponent,
  NgxQrcodeStylingModule,
  Options,
} from 'ngx-qrcode-styling';
import { environment } from '../../../../environments/environment';
import {
  Product,
  ProductQrCtaConfig,
} from '../../../../store/products-store/products.model';

@Component({
  selector: 'app-qr-preview',
  standalone: true,
  imports: [NgxQrcodeStylingModule],
  templateUrl: './qr-preview.component.html',
  styleUrl: './qr-preview.component.scss',
})
export class QrPreviewComponent implements OnInit, OnChanges {
  @Input() product!: Product;
  @Input() qrConfig!: Options;
  @Input() ctaConfig?: ProductQrCtaConfig | null = null;

  @ViewChild(NgxQrcodeStylingComponent, { static: false })
  qrCode!: NgxQrcodeStylingComponent;
  bucketUrl = environment.bucketUrl;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.qrConfig) {
      this.qrCode?.update(this.qrConfig, this.qrConfig);
      this.cd.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['qrConfig'] &&
      JSON.stringify(changes['qrConfig'].currentValue) !==
        JSON.stringify(changes['qrConfig'].previousValue)
    ) {
      this.qrCode?.update(this.qrConfig, this.qrConfig);
      this.cd.detectChanges();
    }
  }
}
