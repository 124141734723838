import { Component, Signal, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import {
  Header,
  Pagination,
  TableActionEvent,
  TableRefreshEvent,
} from '../../../../../store/general-store/general.model';
import { Invitation } from '../../../../../store/invitations-store/invitation.model';
import { InvitationStateSelectors } from '../../../../../store/invitations-store/invitation.selectors';
import { Store } from '@ngxs/store';
import { GetInvitationList } from '../../../../../store/invitations-store/invitation.actions';
import { ObjectToUrlParams } from '../../../../utilities/global.function';
import { DataTableComponent } from '../../../../components/shared-components/data-table/data-table.component';

@Component({
  selector: 'app-invitation-list',
  standalone: true,
  imports: [ButtonModule, DataTableComponent, ConfirmDialogModule],
  providers: [ConfirmationService],
  templateUrl: './invitation-list.component.html',
  styleUrl: './invitation-list.component.scss',
})
export class InvitationListComponent implements OnInit {
  first = 0;
  rows = 5;
  sortBy = 'createdAt';
  sortOrder = -1;
  search = '';
  //Todo: Add proper type here
  selectedFilters: Record<string, any> = {};

  data$: Signal<Invitation[]> = this.store.selectSignal(
    InvitationStateSelectors.getInvitations
  );

  pagination$: Signal<Pagination> = this.store.selectSignal(
    InvitationStateSelectors.getPaginationData
  );

  isProcessing$: Signal<boolean> = this.store.selectSignal(
    InvitationStateSelectors.isLoading
  );

  headers$: Signal<Header[]> = this.store.selectSignal(
    InvitationStateSelectors.getHeaders
  );

  visibleHeaders: Header[] = [];
  skeleton: string[] = [];

  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store,
    private confirmationService: ConfirmationService
  ) {
    this.getInvitationList();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  getInvitationList() {
    this.store.dispatch(
      new GetInvitationList({
        first: this.first,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams(this.selectedFilters),
      })
    );
  }

  actionClicked(_action: TableActionEvent) {
    return;
  }

  refresh(event: TableRefreshEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.sortBy = event.sortBy;
    this.sortOrder = event.sortOrder;
    this.search = event.search;
    this.selectedFilters = event.filters;
    this.getInvitationList();
  }
}
