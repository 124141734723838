<div>
  <app-right-form-base
    [isVisible]="sidebar.visible"
    (sidebarHidden)="sidebar.visible = false"
    [title]="sidebar.title">
    <app-add-cteform
      [data]="sidebar.data"
      [type]="sidebar.type"
      [id]="sidebar.id"></app-add-cteform>
  </app-right-form-base>
  <app-right-form-base
    [isVisible]="kdeSidebar.visible"
    (sidebarHidden)="kdeSidebar.visible = false"
    [widthClass]="kdeSidebar.width"
    [title]="kdeSidebar.title">
    <app-add-kdeform
      (actionSuccess)="updateKde($event)"
      [cteID]="currentCteId"
      [isProductKde]="isProductKDE"></app-add-kdeform>
  </app-right-form-base>
  <p-confirmDialog></p-confirmDialog>
  <div class="flex justify-between mb-4">
    <span>CTEs</span>
    @if (!isProductCtes) {
      <p-button
        class="label-button font-semibold h-fit"
        label="Add Another CTE"
        [text]="true"
        appAccess="add-cte-kde"
        (onClick)="
          sidebar = {
            visible: true,
            title: 'Create New CTE',
            type: 'ADD',
            width: 'w-50',
          }
        " />
    }
  </div>
  @if (ctes$ | async; as orgCTEs) {
    <p-orderList
      [value]="orgCTEs"
      [dragdrop]="true"
      (onReorder)="orderCTEs(orgCTEs, isProductCtes)">
      <ng-template let-cte pTemplate="item">
        <div
          class="flex flex-wrap align-items-center"
          (click)="preventSelection($event)">
          <p-accordion
            class="w-full"
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full items-center">
                  <i class="pi pi-align-justify mr-2 text-gray-300"></i>
                  <i class="{{ cte.icon }} mr-2 text-xl text-blue-500"></i>
                  <span>
                    {{ cte.title }}
                  </span>
                  <p-button
                    class="ml-auto label-button font-semibold content-center z-20"
                    label="Add Another KDE"
                    [text]="true"
                    (click)="addKDE(cte, $event)" />
                  <div class="flex mr-[15px] ml-[15px]">
                    <app-table-action-button
                      [disabled]="cte.isDefault"
                      [actionType]="'EDIT'"
                      (click)="
                        $event.preventDefault(); $event.stopPropagation()
                      "
                      (actionClicked)="actionClickedCTE($event, cte.id, cte)" />
                    <app-table-action-button
                      [disabled]="cte.isDefault"
                      [actionType]="'DELETE'"
                      (click)="
                        $event.preventDefault(); $event.stopPropagation()
                      "
                      (actionClicked)="actionClickedCTE($event, cte.id, cte)" />
                  </div>
                </span>
              </ng-template>
              @if (cte.kdes?.length > 0 || cte.productKdes?.length > 0) {
                <div class="ml-5 text-xs text-stone-400 pb-1 pt-1">KDEs</div>
                <p-orderList
                  [value]="isProductCtes ? cte.productKdes : cte.kdes"
                  [dragdrop]="true"
                  (onReorder)="listReordered(cte)"
                  class="child-list">
                  <ng-template let-kde pTemplate="item">
                    <div
                      class="flex flex-wrap align-items-center gap-3 py-3.5 px-4"
                      (click)="preventSelection($event)">
                      <div
                        class="flex-1 flex flex-column justify-between gap-2">
                        <span
                          class="font-medium text-base text-gray-800 inline-flex items-center"
                          ><i class="pi pi-align-justify mr-2 text-gray-300"></i
                          >{{ kde.title }}</span
                        >
                        @if (isProductCtes) {
                          <div class="flex ml-[15px]">
                            <app-table-action-button
                              [disabled]="kde.isDefault"
                              [actionType]="'EDIT'"
                              (click)="
                                $event.preventDefault();
                                $event.stopPropagation()
                              "
                              (actionClicked)="
                                actionClickedKDE($event, kde.id, kde)
                              " />
                            <app-table-action-button
                              [disabled]="kde.isDefault"
                              [actionType]="'DELETE'"
                              (click)="
                                $event.preventDefault();
                                $event.stopPropagation()
                              "
                              (actionClicked)="
                                actionClickedKDE($event, kde.id, cte)
                              " />
                          </div>
                        }
                      </div>
                    </div>
                  </ng-template>
                </p-orderList>
              }
            </p-accordionTab>
          </p-accordion>
        </div>
      </ng-template>
    </p-orderList>
  }
</div>
@if (isProductCtes) {
  <div class="flex justify-end mt-[20px]">
    <app-action-button
      [disabled]="false"
      (actionClicked)="updateProduct()"
      label="Update & Close"></app-action-button>
  </div>
}
