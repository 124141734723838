<div class="grid grid-cols-4">
  <div class="menu-section">
    @if (product$()) {
      @for (module of pageModules; track module) {
        <p
          class="module-item"
          [ngClass]="{ active: selectedPageModule?.id === module.id }"
          (click)="
            selectedPageModule = module;
            selectedPageModuleTemplate = null;
            selectPageModuleTemplate()
          ">
          {{ module.title }}
        </p>
      }
    }
    @if (this.uploadedFiles$()['productTemplateComponentImage_42']) {
      <img
        [src]="
          this.uploadedFiles$()['productTemplateComponentImage_42'][0].objectURL
        "
        alt="" />
    }
  </div>
  <div class="col-span-2 center-section">
    <div class="mb-[20px]">
      <p>Select a template:</p>
      @for (
        template of selectedPageModule?.pageModuleTemplates;
        track template;
        let i = $index
      ) {
        <div class="template-card" (click)="addModule(template)">
          <p>{{ template.title }} {{ i + 1 }}</p>
        </div>
      }
    </div>
    @if (selectedPageModuleTemplate) {
      <p-tabView>
        <p-tabPanel header="Components">
          <p-orderList
            class="w-full sidebar-orderlist"
            [value]="selectedPageModuleTemplateComponents"
            [dragdrop]="selectedPageModule?.rules?.canAddNewComponents"
            (onReorder)="reorderComponent()">
            <ng-template let-component pTemplate="item">
              @switch (component.type) {
                @case ('text') {
                  <div class="component-item">
                    <div>
                      <span class="pi pi-pen-to-square mr-[10px]"></span>
                      <span>{{ component.label }}</span>
                    </div>
                    <div>
                      <span
                        *ngIf="selectedPageModule?.rules?.canAddNewComponents"
                        class="pi pi-trash text-danger cursor-pointer"
                        (click)="deleteComponent(component.id)"></span>
                    </div>
                  </div>
                }
                @case ('file') {
                  <div class="component-item">
                    <div>
                      <span class="pi pi-image mr-[10px]"></span>
                      <span>{{ component.label }}</span>
                    </div>
                    <div>
                      <span
                        *ngIf="selectedPageModule?.rules?.canAddNewComponents"
                        class="pi pi-trash text-danger cursor-pointer"
                        (click)="deleteComponent(component.id)"></span>
                    </div>
                  </div>
                }
                @case ('image') {
                  <div class="component-item">
                    <div>
                      <span class="pi pi-image mr-[10px]"></span>
                      <span>{{ component.label }}</span>
                    </div>
                    <div>
                      <span
                        *ngIf="selectedPageModule?.rules?.canAddNewComponents"
                        class="pi pi-trash text-danger cursor-pointer"
                        (click)="deleteComponent(component.id)"></span>
                    </div>
                  </div>
                }
                @case ('video') {
                  <div class="component-item">
                    <div>
                      <span class="pi pi-youtube mr-[10px]"></span>
                      <span>{{ component.label }}</span>
                    </div>
                    <div>
                      <span
                        *ngIf="selectedPageModule?.rules?.canAddNewComponents"
                        class="pi pi-trash text-danger cursor-pointer"
                        (click)="deleteComponent(component.id)"></span>
                    </div>
                  </div>
                }
                @case ('file') {
                  <div class="component-item">
                    <div>
                      <span class="pi pi-image mr-[10px]"></span>
                      <span>{{ component.label }}</span>
                    </div>
                    <div>
                      <span
                        *ngIf="selectedPageModule?.rules?.canAddNewComponents"
                        class="pi pi-trash text-danger cursor-pointer"
                        (click)="deleteComponent(component.id)"></span>
                    </div>
                  </div>
                }
                @case ('boolean') {
                  <div class="component-item">
                    <div>
                      <span class="pi pi-calendar mr-[10px]"></span>
                      <span>{{ component.label }}</span>
                    </div>
                    <div>
                      <span
                        *ngIf="selectedPageModule?.rules?.canAddNewComponents"
                        class="pi pi-trash text-danger cursor-pointer"
                        (click)="deleteComponent(component.id)"></span>
                    </div>
                  </div>
                }
                @case ('link') {
                  <div class="component-item">
                    <div>
                      <span class="pi pi-link mr-[10px]"></span>
                      <span>{{ component.label }}</span>
                    </div>
                    <div>
                      <span
                        *ngIf="selectedPageModule?.rules?.canAddNewComponents"
                        class="pi pi-trash text-danger cursor-pointer"
                        (click)="deleteComponent(component.id)"></span>
                    </div>
                  </div>
                }
              }
            </ng-template>
          </p-orderList>
          <div class="mt-[15px]">
            @if (selectedPageModule?.rules?.canAddNewComponents) {
              <div
                class="component-item component-button"
                (click)="showComponentItemOptions = !showComponentItemOptions">
                <span class="pi pi-plus mr-[10px]"></span>
                Add
              </div>
              @if (showComponentItemOptions) {
                <div class="component-item-options">
                  @for (option of componentItemOptions; track option) {
                    <div (click)="addComponent(option)">
                      <span [class]="option.icon + ' mr-[10px]'"></span>
                      {{ option.label }}
                    </div>
                  }
                </div>
              }
            }
          </div>
        </p-tabPanel>
        <p-tabPanel header="Values & Styles">
          @for (
            component of sortArray(
              selectedPageModuleTemplate!.pageModuleTemplateDataContents
            );
            track component
          ) {
            <form>
              @switch (component.type) {
                @case ('text') {
                  <div class="mb-[15px]">
                    <label for="">{{ component.label }}</label>
                    <input
                      pInput
                      class="w-[100%]"
                      type="text"
                      [(ngModel)]="component.value.text"
                      (change)="updateComponent(component)"
                      name="component.label" />
                  </div>
                }
                @case ('link') {
                  <div class="mb-[15px]">
                    <label for="">{{ component.label }}</label>
                    <input pInput class="w-[100%]" type="text" />
                  </div>
                }
                @case ('video') {
                  <div class="mb-[15px]">
                    <label for="">{{ component.label }}</label>
                    <input
                      pInput
                      class="w-[100%]"
                      type="text"
                      [name]="component.label"
                      (change)="updateComponent(component)"
                      [(ngModel)]="component.value.url" />
                  </div>
                }
                @case ('image') {
                  <div class="flex">
                    <div class="upload-container mb-[15px]">
                      <input
                        type="file"
                        [id]="'attachment_' + component.id"
                        class="file-input"
                        (change)="upload($event, component)" />
                      <label
                        [for]="'attachment_' + component.id"
                        class="upload-label">
                        <i class="pi pi-upload"></i>
                        <p>{{ component.label }}</p>
                      </label>
                    </div>
                    <img
                      [src]="component.value.url"
                      [alt]="component.value.altText"
                      class="w-[150px] h-[150px] ml-[20px] object-cover" />
                  </div>
                }
                @case ('file') {
                  <div class="flex">
                    <div class="upload-container mb-[15px]">
                      <input
                        type="file"
                        [id]="'attachment_' + component.id"
                        class="file-input"
                        (change)="upload($event, component)" />
                      <label
                        [for]="'attachment_' + component.id"
                        class="upload-label">
                        <i class="pi pi-upload"></i>
                        <p>{{ component.label }}</p>
                      </label>
                    </div>
                    <img
                      [src]="component.value.url"
                      [alt]="component.value.altText"
                      class="w-[150px] h-[150px] ml-[20px] object-cover" />
                  </div>
                }
                @case ('boolean') {
                  <div class="mb-[15px]">
                    <label for="">{{ component.label }}</label>
                    <div class="mt-[5px]">
                      <p-inputSwitch
                        [name]="component.label"
                        (onChange)="updateComponent(component)"
                        [(ngModel)]="component.value.isActive" />
                    </div>
                  </div>
                }
              }
            </form>
          }
        </p-tabPanel>
      </p-tabView>
    }
  </div>
  <div class="preview-section">
    @for (content of pageContent; track content; let i = $index) {
      @if (
        content.slug.startsWith('header-data') ||
        content.slug.startsWith('header-template')
      ) {
        <div
          class="relative preview-section-module"
          [ngClass]="{
            active: content?.id === selectedPageModuleTemplate?.id,
          }">
          <div class="overlay-buttons">
            <button class="btn" (click)="editModule(content)">
              <i class="pi pi-pencil"></i>
            </button>
            <button class="btn" (click)="deleteModule(content.id)">
              <i class="pi pi-trash"></i>
            </button>
            <button
              class="btn"
              *ngIf="i !== 0"
              (click)="reorderModule(content, -1)">
              <i class="pi pi-arrow-up"></i>
            </button>
            <button
              class="btn"
              *ngIf="i !== pageContent.length - 1"
              (click)="reorderModule(content, 1)">
              <i class="pi pi-arrow-down"></i>
            </button>
          </div>
          <div
            class="image-container"
            [ngStyle]="{
              'background-image':
                'url(' +
                content.pageModuleTemplateDataContents[1].value.url +
                ')',
            }">
            <img
              [src]="content.pageModuleTemplateDataContents[0].value.url"
              alt="content.pageModuleTemplateDataContents[0].value.altText"
              class="logo" />
            <span class="icon pi pi-verified"></span>
          </div>
          <div class="text-center">
            <h2>{{ content.pageModuleTemplateDataContents[2].value.text }}</h2>
          </div>
          <div class="text-center text-justify">
            <p class="">
              {{ content.pageModuleTemplateDataContents[4].value.text }}
            </p>
          </div>
          @if (content.pageModuleTemplateDataContents[3].value.isActive) {
            <div
              class="expiration-date-container"
              [ngStyle]="{
                borderColor:
                  content.pageModuleTemplateDataContents[3].value.color,
              }">
              <div class="flex items-center">
                <span
                  class="pi pi-clock mr-[10px] expiration-date-icon"
                  [ngStyle]="{
                    color:
                      content.pageModuleTemplateDataContents[3].value.color,
                  }"></span>
                <div>
                  <small>{{
                    content.pageModuleTemplateDataContents[3].label
                  }}</small>
                  <p class="m-[0px] font-bold">
                    {{ content.pageModuleTemplateDataContents[3].value.date }}
                  </p>
                </div>
              </div>
              <div>
                <span
                  class="expiration-date-text"
                  [ngStyle]="{
                    backgroundColor:
                      content.pageModuleTemplateDataContents[3].value.color,
                  }"
                  >{{
                    content.pageModuleTemplateDataContents[3].value.text
                  }}</span
                >
              </div>
            </div>
          }
          <div class="text-center">
            <h2>{{ content.pageModuleTemplateDataContents[5].value.text }}</h2>
          </div>
          <div class="text-center text-justify">
            <p class="">
              {{ content.pageModuleTemplateDataContents[6].value.text }}
            </p>
          </div>
        </div>
      }
      @if (
        content.slug.startsWith('text-image-video-data') ||
        content.slug.startsWith('text-image-video-template')
      ) {
        <div
          class="relative preview-section-module"
          [ngClass]="{
            active: content?.id === selectedPageModuleTemplate?.id,
          }">
          <div class="overlay-buttons">
            <button class="btn" (click)="editModule(content)">
              <i class="pi pi-pencil"></i>
            </button>
            <button class="btn" (click)="deleteModule(content.id)">
              <i class="pi pi-trash"></i>
            </button>
            <button
              class="btn"
              *ngIf="i !== 0"
              (click)="reorderModule(content, -1)">
              <i class="pi pi-arrow-up"></i>
            </button>
            <button
              class="btn"
              *ngIf="i !== pageContent.length - 1"
              (click)="reorderModule(content, 1)">
              <i class="pi pi-arrow-down"></i>
            </button>
          </div>
          @for (data of content.pageModuleTemplateDataContents; track data) {
            @if (data.type === 'text') {
              <div>
                <p>{{ data.value.text }}</p>
              </div>
            }
            @if (data.type === 'image') {
              <div>
                <img
                  [src]="data.value.url"
                  [alt]="data.value.altText"
                  class="w-[100%]" />
              </div>
            }
            @if (data.type === 'video') {
              <div class="text-center mt-3">
                <iframe
                  width="100%"
                  height="300"
                  [src]="sanitizeUrl(data.value.url ?? '')"
                  [title]="data.value.caption"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen></iframe>
              </div>
            }
          }
        </div>
      }
    }
  </div>
</div>
