<div class="w-full p-8">
  <p-confirmDialog></p-confirmDialog>
  <app-right-form-base
    [isVisible]="sidebar.visible"
    [widthClass]="sidebar.width"
    (sidebarHidden)="sidebar.visible = false"
    [title]="sidebar.title">
    @switch (sidebar.formType) {
      @case ('qrOrder') {
        <app-create-qr-order-form
          [data]="sidebar.data"
          [type]="sidebar.type"
          [id]="sidebar.id"
          (actionSuccess)="getQrOrderList(); sidebar.visible = false">
        </app-create-qr-order-form>
      }
      @case ('qrDownload') {
        <app-download-qr-order-form
          [data]="sidebar.data"
          [type]="sidebar.type"
          [id]="sidebar.id"
          (actionSuccess)="
            getQrOrderList(); sidebar.visible = false
          "></app-download-qr-order-form>
      }
    }
  </app-right-form-base>
  <div class="flex justify-between items-center">
    <h2>{{ pageTitle }}</h2>
    <div class="d-flex gap-3">
      <p-button icon="pi pi-upload" label="Bulk Upload" [disabled]="true" />
      <p-button
        appAccess="add-qr-order"
        icon="pi pi-plus"
        label="Create QR Order"
        (onClick)="
          sidebar = {
            visible: true,
            title: 'Create QR Order',
            type: 'ADD',
            formType: 'qrOrder',
            width: 'w-75',
          }
        " />
    </div>
  </div>

  <app-data-table
    [first]="first"
    export="QR_GENERATE_ORDER"
    [rows]="rows"
    [sortBy]="sortBy"
    [sortOrder]="sortOrder"
    [selectedFilters]="selectedFilters"
    [data$]="this.data$"
    [headers$]="this.headers$"
    [isProcessing$]="this.isProcessing$"
    [pagination$]="this.pagination$"
    (actionClickedEvent)="actionClicked($event)"
    (refreshEvent)="refresh($event)" />
</div>
