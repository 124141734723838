<div class="h-screen">
  <div class="flex h-full">
    <div class="hidden lg:flex w-7/12 flex-col justify-between p-6">
      <a href="/">
        <img
          [src]="bucketUrl + '/images/qrlogo.png'"
          alt="Logo"
          class="w-24 h-auto" />
      </a>
      <!-- Bottom Content -->
      <div class="text-white text-7xl leading-tight text-80px font-light">
        <p>
          Get ready
          <br />
          for an amazing
          <br />
          experience
          <br />
          <span class="font-bold">with reGenesis.</span>
        </p>
        <div class="footer-buttons">
          <div>Terms & Conditions</div>
          <div style="margin-left: 32px">Privacy Policy</div>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-5/12 h-screen">
      <div
        class="form-content m-6 box-border p-6 rounded-3xl bg-white overflow-auto">
        <div class="m-6">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
