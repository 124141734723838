<div class="flex h-full">
  <!-- Product Scan Details Section -->
  <div class="w-2/3 pr-8">
    <div class="card flex justify-content-center">
      <p-accordion class="w-full" [activeIndex]="0">
        <form [formGroup]="form">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <span
                class="flex items-center justify-between gap-2 w-full overflow-clip">
                <span class="font-medium text-sm white-space-nowrap uppercase">
                  Product Details
                </span>
                <div class="flex-1 h-px bg-gray-300 ml-4"></div>
              </span>
            </ng-template>
            <p class="text-base font-normal">Product Name</p>
            <input
              type="text"
              pInputText
              formControlName="name"
              placeholder="Select product or search by product ID"
              class="w-full"
              [class.input-danger]="
                form.get('name')?.invalid && form.get('name')?.touched
              " />
            <app-error-message
              [control]="form.get('name')"
              label="Name"></app-error-message>

            <p class="text-base font-normal">Product Description</p>
            <input
              type="text"
              pInputText
              formControlName="description"
              placeholder="Enter product description here"
              class="w-full"
              [class.input-danger]="
                form.get('description')?.invalid &&
                form.get('description')?.touched
              " />
            <app-error-message
              [control]="form.get('description')"
              label="Description"></app-error-message>
          </p-accordionTab>
        </form>
        <form [formGroup]="socialImagesForm">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <span
                class="flex items-center justify-between gap-2 w-full overflow-clip">
                <span class="font-medium text-sm white-space-nowrap uppercase">
                  Product Links
                </span>
                <div class="flex-1 h-px bg-gray-300 ml-4"></div>
              </span>
            </ng-template>
            <div formArrayName="socialImages">
              @for (control of socialImages.controls; track $index) {
                <div class="mb-1">
                  <div
                    [formGroupName]="$index"
                    class="flex items-center justify-between">
                    <input
                      formControlName="key"
                      placeholder="Key"
                      class="w-3/12" />
                    <input
                      formControlName="value"
                      placeholder="Value"
                      class="w-2/3" />
                    <p-button
                      class="remove-button"
                      (click)="removeSocialLink($index)"
                      icon="pi pi-times"
                      [rounded]="true"
                      [text]="true"
                      severity="danger" />
                  </div>
                </div>
              }
            </div>
            <div class="w-max">
              <p-button
                label="Add New Link +"
                size="small"
                (click)="addNewDataRow(6)" />
            </div>
          </p-accordionTab>
        </form>
        <form [formGroup]="originInfoForm">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <span
                class="flex items-center justify-between gap-2 w-full overflow-clip">
                <span class="font-medium text-sm white-space-nowrap uppercase">
                  Origin Info
                  <p-toggleButton
                    (click)="$event.stopPropagation()"
                    formControlName="isEnabled"
                    onLabel="Enabled"
                    offLabel="Disabled" />
                </span>
                <div class="flex-1 h-px bg-gray-300 ml-4"></div>
              </span>
            </ng-template>
            <div formArrayName="originInfo">
              @for (control of originInfo.controls; track $index) {
                <div class="mb-1">
                  <div
                    [formGroupName]="$index"
                    class="flex items-center justify-between">
                    <input
                      formControlName="key"
                      placeholder="Key"
                      class="w-3/12" />
                    <input
                      formControlName="value"
                      placeholder="Value"
                      class="w-2/3" />
                    <p-button
                      class="remove-button"
                      (click)="removeOriginInfo($index)"
                      icon="pi pi-times"
                      [rounded]="true"
                      [text]="true"
                      severity="danger" />
                  </div>
                </div>
              }
            </div>
            <div class="w-max">
              <p-button
                label="Add New Origin +"
                size="small"
                (click)="addNewDataRow(1)" />
            </div>
          </p-accordionTab>
        </form>
        <form [formGroup]="nutritionalInfoForm">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <span
                class="flex items-center justify-between gap-2 w-full overflow-clip">
                <span class="font-medium text-sm white-space-nowrap uppercase">
                  Nutritional Info
                  <p-toggleButton
                    (click)="$event.stopPropagation()"
                    formControlName="isEnabled"
                    onLabel="Enabled"
                    offLabel="Disabled" />
                </span>
                <div class="flex-1 h-px bg-gray-300 ml-4"></div>
              </span>
            </ng-template>
            <div formArrayName="nutritionalInfo">
              @for (control of nutritionalInfo.controls; track $index) {
                <div class="mb-1">
                  <div
                    [formGroupName]="$index"
                    class="flex items-center justify-between">
                    <input
                      formControlName="key"
                      placeholder="Key"
                      class="w-3/12" />
                    <input
                      formControlName="value"
                      placeholder="Value"
                      class="w-2/3" />
                    <p-button
                      class="remove-button"
                      (click)="removeNutritionalInfo($index)"
                      icon="pi pi-times"
                      [rounded]="true"
                      [text]="true"
                      severity="danger" />
                  </div>
                </div>
              }
            </div>
            <div class="w-max">
              <p-button
                label="Add New Nutrient +"
                size="small"
                (click)="addNewDataRow(2)" />
            </div>
          </p-accordionTab>
        </form>
        <form [formGroup]="safetyAndQualityForm">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <span
                class="flex items-center justify-between gap-2 w-full overflow-clip">
                <span class="font-medium text-sm white-space-nowrap uppercase">
                  Safety & QA
                  <p-toggleButton
                    (click)="$event.stopPropagation()"
                    formControlName="isEnabled"
                    onLabel="Enabled"
                    offLabel="Disabled" />
                </span>
                <div class="flex-1 h-px bg-gray-300 ml-4"></div>
              </span>
            </ng-template>
            <div formArrayName="safetyInfo">
              @for (control of safetyInfo.controls; track $index) {
                <div class="mb-1">
                  <div
                    [formGroupName]="$index"
                    class="flex items-center justify-between">
                    <input
                      formControlName="key"
                      placeholder="Key"
                      class="w-3/12" />
                    <input
                      formControlName="value"
                      placeholder="Value"
                      class="w-2/3" />
                    <p-button
                      class="remove-button"
                      (click)="removeSafetyInfo($index)"
                      icon="pi pi-times"
                      [rounded]="true"
                      [text]="true"
                      severity="danger" />
                  </div>
                </div>
              }
            </div>
            <div class="w-max">
              <p-button
                label="Add New Safety Instruction +"
                size="small"
                (click)="addNewDataRow(3)" />
            </div>
          </p-accordionTab>
        </form>
        <form [formGroup]="traceabilityForm">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <span
                class="flex items-center justify-between gap-2 w-full overflow-clip">
                <span class="font-medium text-sm white-space-nowrap uppercase">
                  Traceability
                  <p-toggleButton
                    (click)="$event.stopPropagation()"
                    formControlName="isEnabled"
                    onLabel="Enabled"
                    offLabel="Disabled" />
                </span>
                <div class="flex-1 h-px bg-gray-300 ml-4"></div>
              </span>
            </ng-template>
            <div formArrayName="traceabilityInfo">
              @for (control of traceabilityInfo.controls; track $index) {
                <div class="mb-1">
                  <div
                    [formGroupName]="$index"
                    class="flex items-center justify-between">
                    <input
                      formControlName="key"
                      placeholder="Key"
                      class="w-3/12" />
                    <input
                      formControlName="value"
                      placeholder="Value"
                      class="w-2/3" />
                    <p-button
                      class="remove-button"
                      (click)="removeTraceabilityInfo($index)"
                      icon="pi pi-times"
                      [rounded]="true"
                      [text]="true"
                      severity="danger" />
                  </div>
                </div>
              }
            </div>
            <div class="w-max">
              <p-button
                label="Add New Traceability Info +"
                size="small"
                (click)="addNewDataRow(4)" />
            </div>
          </p-accordionTab>
        </form>
        <form [formGroup]="usageInstructionsForm">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <span
                class="flex items-center justify-between gap-2 w-full overflow-clip">
                <span class="font-medium text-sm white-space-nowrap uppercase">
                  Usage Instructions
                  <p-toggleButton
                    (click)="$event.stopPropagation()"
                    formControlName="isEnabled"
                    onLabel="Enabled"
                    offLabel="Disabled" />
                </span>
                <div class="flex-1 h-px bg-gray-300 ml-4"></div>
              </span>
            </ng-template>
            <div formArrayName="usageInfo">
              @for (control of usageInfo.controls; track $index) {
                <div class="mb-1">
                  <div
                    [formGroupName]="$index"
                    class="flex items-center justify-between">
                    <input
                      formControlName="key"
                      placeholder="Key"
                      class="w-3/12" />
                    <input
                      formControlName="value"
                      placeholder="Value"
                      class="w-2/3" />
                    <p-button
                      class="remove-button"
                      (click)="removeUsageInfo($index)"
                      icon="pi pi-times"
                      [rounded]="true"
                      [text]="true"
                      severity="danger" />
                  </div>
                </div>
              }
            </div>
            <div class="w-max">
              <p-button
                label="Add New Traceability Info +"
                size="small"
                (click)="addNewDataRow(5)" />
            </div>
          </p-accordionTab>
        </form>
      </p-accordion>
    </div>
    <app-action-button
      class="absolute bottom-0 w-2/3 pr-14"
      [disabled]="
        !form.valid ||
        !originInfoForm.valid ||
        !socialImagesForm.valid ||
        !traceabilityForm.valid ||
        !nutritionalInfoForm.valid ||
        !safetyAndQualityForm.valid ||
        !usageInstructionsForm.valid
      "
      (actionClicked)="saveTemplate()"
      [actionInProgress]="isProcessing$()"
      label="Save"></app-action-button>
  </div>

  <!-- Template Preview Section -->
  <div class="w-1/3 pl-4 border-l bg-gray-50 rounded-lg">
    Template Preview
    <img class="w-full" [src]="productImage" alt="Product" />
    <div class="font-semibold text-gray-700">Product Name</div>
    <div class="text-gray-600">
      <div class="text-lg font-bold">{{ form.value.name }}</div>
      <div class="text-sm font-normal">{{ form.value.description }}</div>
    </div>
    <div class="flex-1 h-px bg-gray-300 mt-6"></div>
    <p-orderList
      [value]="sections"
      class="w-full sidebar-orderlist"
      [dragdrop]="true"
      (onReorder)="reOrderedSection()">
      <ng-template let-section pTemplate="item">
        @if (section.content) {
          <div class="pt-4 pb-4 shadow-md rounded-md">
            <div class="font-semibold text-gray-700">{{ section.label }}</div>
            <div [innerHTML]="section.content" class="text-gray-600"></div>
            <div class="flex-1 h-px bg-gray-300 mt-6"></div>
          </div>
        }
      </ng-template>
    </p-orderList>
  </div>
</div>
