import { Component, effect, HostListener, Signal, signal } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { UserLogin } from '../../../../store/users-store/user.actions';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { ActionButtonComponent } from '../../shared-components/action-button/action-button.component';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ActionButtonComponent,
    ErrorMessageComponent,
  ],
  templateUrl: './sign-in.component.html',
})
export class SignInComponent {
  form: FormGroup;
  processing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );
  isLoggedIn$: Signal<boolean> = this.store.selectSignal(
    UserStateSelectors.isLoggedIn
  );
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private router: Router
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    /*     effect(() => {
      if (this.isLoggedIn$() === true) {
        this.router.navigate(['/dashboards/analytical'], {
          replaceUrl: true,
        });
      }
    }); */
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login();
    }
  }

  login() {
    if (this.form.invalid) return;
    this.store.dispatch(
      new UserLogin({
        email: this.form.get('email')?.value,
        password: this.form.get('password')?.value,
      })
    );
  }
}
