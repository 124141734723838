<form [formGroup]="form" class="pt-6">
  <div class="grid grid-cols-2 gap-4">
    <!-- Select Organization -->
    <div>
      <p class="text-base font-normal">Select Organization</p>
      <p-dropdown
        formControlName="organizationId"
        [options]="organizations$()"
        optionLabel="name"
        optionValue="id"
        placeholder="Select Organization"
        class="w-full"
        [virtualScroll]="true"
        [itemSize]="30"
        [class.input-danger]="
          form.get('organizationId')?.invalid &&
          form.get('organizationId')?.touched
        " />
      <app-error-message
        [control]="form.get('organizationId')"
        label="Organization"></app-error-message>
    </div>

    <!-- First Name -->
    <div>
      <p class="text-base font-normal">First Name</p>
      <input
        type="text"
        pInputText
        formControlName="firstName"
        placeholder="James"
        class="w-full"
        [class.input-danger]="
          form.get('firstName')?.invalid && form.get('firstName')?.touched
        " />
      <app-error-message
        [control]="form.get('firstName')"
        label="First Name"></app-error-message>
    </div>

    <!-- Last Name -->
    <div>
      <p class="text-base font-normal">Last Name</p>
      <input
        type="text"
        pInputText
        formControlName="lastName"
        placeholder="McKenzie"
        class="w-full"
        [class.input-danger]="
          form.get('lastName')?.invalid && form.get('lastName')?.touched
        " />
      <app-error-message
        [control]="form.get('lastName')"
        label="Last Name"></app-error-message>
    </div>

    <!-- Email -->
    <div>
      <p class="text-base font-normal">Email</p>
      <input
        type="email"
        formControlName="email"
        placeholder="example@example.com"
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('email')?.invalid && form.get('email')?.touched
        " />
      <app-error-message
        [control]="form.get('email')"
        label="Email"></app-error-message>
    </div>

    <!-- Password -->
    <div>
      <p class="text-base font-normal">Password</p>
      <p-iconField iconPosition="right">
        <p-inputIcon
          (click)="passwordVisibility = !passwordVisibility"
          [styleClass]="passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'" />
        <input
          [type]="passwordVisibility ? 'text' : 'password'"
          formControlName="password"
          placeholder="************"
          pInputText
          class="w-full"
          [class.input-danger]="
            form.get('password')?.invalid && form.get('password')?.touched
          " />
      </p-iconField>
      <app-error-message
        [control]="form.get('password')"
        label="Password"></app-error-message>
    </div>

    <!-- Confirm Password -->
    <div>
      <p class="text-base font-normal">Confirm Password</p>
      <p-iconField iconPosition="right">
        <p-inputIcon
          (click)="confirmPasswordVisibility = !confirmPasswordVisibility"
          [styleClass]="
            confirmPasswordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'
          " />
        <input
          [type]="confirmPasswordVisibility ? 'text' : 'password'"
          formControlName="confirmPassword"
          placeholder="************"
          pInputText
          class="w-full"
          [class.input-danger]="
            form.get('confirmPassword')?.invalid &&
            form.get('confirmPassword')?.touched
          " />
      </p-iconField>
      <app-error-message
        [control]="form.get('confirmPassword')"
        label="Confirm Password"></app-error-message>
    </div>
    <div class="text-xs font-normal">
      @for (validation of passwordValidations; track $index) {
        <p class="password-text flex items-center !mb-1">
          <span
            class="password-icon mr-2"
            [class.text-teal-700]="validation.valid"
            [class.text-red-600]="!validation.valid">
            @if (!validation.valid) {
              <i class="pi pi-times-circle"></i>
            } @else {
              <i class="pi pi-verified"></i>
            }
          </span>
          <span>
            {{ validation.message }}
          </span>
        </p>
      }
      <p class="password-text flex items-center !mb-1">
        <span
          class="password-icon mr-2"
          [class.text-teal-700]="
            !form.get('confirmPassword')?.hasError('mismatch')
          "
          [class.text-red-600]="
            form.get('confirmPassword')?.hasError('mismatch')
          ">
          <i
            class="pi"
            [ngClass]="{
              'pi-times-circle': form
                .get('confirmPassword')
                ?.hasError('mismatch'),
              'pi-verified': !form.get('confirmPassword')?.hasError('mismatch'),
            }"></i>
        </span>
        <span *ngIf="form.get('confirmPassword')?.hasError('mismatch')"
          >Passwords do not match</span
        >
        <span *ngIf="!form.get('confirmPassword')?.hasError('mismatch')"
          >Passwords are matching</span
        >
      </p>
    </div>
  </div>
  <div class="sidebar-form-button">
    <app-action-button
      [disabled]="!this.form.valid || processing$()"
      (actionClicked)="addOrganizationAdmin()"
      label="Submit"></app-action-button>
  </div>
</form>
