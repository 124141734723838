<small class="input-error">
  @if (control?.touched && control?.hasError('email')) {
    Invalid email <strong>format</strong>
  } @else if (control?.touched && control?.hasError('required')) {
    {{ capitalizeLabel }} is <strong>required</strong>
  } @else if (control?.touched && control?.hasError('minlength')) {
    {{ capitalizeLabel }} must be at least <strong>8 digits</strong>
  } @else if (control?.touched && control?.hasError('maxlength')) {
    {{ capitalizeLabel }} must not be above
    <strong>{{
      capitalizeLabel === 'CTA Text' ? '200 characters' : '15 digits'
    }}</strong>
  } @else if (control?.touched && control?.hasError('max')) {
    {{ capitalizeLabel }} must not be above
    <strong>{{ control?.errors?.['max']?.max }}</strong>
  } @else if (control?.touched && control?.hasError('min')) {
    {{ capitalizeLabel }} must be at least
    <strong>{{ control?.errors?.['min']?.min }}</strong>
  } @else if (
    control?.touched &&
    control?.hasError('pattern') &&
    control?.errors?.['pattern']?.requiredPattern === '^[0-9]*$'
  ) {
    <strong>Only numbers</strong> are allowed
  } @else if (
    control?.touched &&
    control?.hasError('pattern') &&
    control?.errors?.['pattern']?.requiredPattern === '/^\\d{13}$/'
  ) {
    GLN <strong>length</strong> must be <strong>13</strong>
  } @else {
    &nbsp;
  }
</small>
