import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { ActionButtonComponent } from '../action-button/action-button.component';
import { Store } from '@ngxs/store';
import { ClearUploadedFiles } from '../../../../store/general-store/general.actions';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-right-form-base',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [SidebarModule, ActionButtonComponent, CommonModule],
  templateUrl: './right-form-base.component.html',
  styleUrl: './right-form-base.component.scss',
})
export class RightFormBaseComponent {
  @Input() isVisible = false;
  @Input() title = '';
  @Input() widthClass = 'w-50';
  @Output() sidebarHidden = new EventEmitter<void>();

  constructor(private store: Store) {}

  clearUploadedFiles() {
    this.store.dispatch(new ClearUploadedFiles());
  }
}
