import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ActionButtonComponent } from '../../shared-components/action-button/action-button.component';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Select, Store } from '@ngxs/store';
import {
  AddOrganizationCTE,
  UpdateOrganizationCTE,
} from '../../../../store/organizations-store/organizations.actions';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { CardModule } from 'primeng/card';
import { NgClass, NgFor } from '@angular/common';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { Observable } from 'rxjs';
import { CDEIcon, CTE } from '../../../../store/general-store/general.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-cteform',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ActionButtonComponent,
    ErrorMessageComponent,
    InputTextareaModule,
    CardModule,
    NgFor,
    NgClass,
    CommonModule,
  ],
  templateUrl: './add-cte-form.component.html',
  styleUrl: './add-cte-form.component.scss',
})
export class AddCTEFormComponent implements OnChanges {
  @Input() data?: CTE;
  @Input() id?: number;
  @Input() type?: string;

  selectedIcon: string = 'pi pi-check-circle';
  form: FormGroup;
  processing$ = this.store.selectSignal(
    OrganizationStateSelectors.isOrganizationStoreProcessing
  );
  @Select(GeneralStateSelectors.getCDEIcons) cdeIcons$:
    | Observable<CDEIcon[]>
    | undefined;
  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.action();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['data'] &&
      this.data &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.form.patchValue({
        title: this.data?.title || '',
        description: this.data?.description || '',
      });
      this.selectedIcon = this.data!.icon;
    } else {
      this.form.reset();
    }
  }

  action() {
    const { title, description } = this.form.value;
    if (!title || !description) return;
    if (this.type === 'ADD') {
      this.store
        .dispatch(
          new AddOrganizationCTE({
            ...this.form.value,
            type: 'STANDARD',
            icon: this.selectedIcon,
          })
        )
        .subscribe(() => {
          this.form.reset();
        });
    } else if (this.type === 'EDIT' && this.id) {
      this.store
        .dispatch(
          new UpdateOrganizationCTE(this.id, {
            ...this.form.value,
            type: 'STANDARD',
            icon: this.selectedIcon,
          })
        )
        .subscribe(() => {
          this.form.reset();
        });
    }
  }

  selectIcon(icon: { key: string; value: string }) {
    this.selectedIcon = icon.value;
  }
}
