import { Component } from '@angular/core';

@Component({
  selector: 'app-profile-security',
  standalone: true,
  imports: [],
  templateUrl: './profile-security.component.html',
  styleUrl: './profile-security.component.scss',
})
export class ProfileSecurityComponent {}
