import { Action, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import {
  AcceptInvite,
  AddInvitation,
  GetInvitationList,
} from './invitation.actions';

import { InvitationStateModel } from './invitation.model';
import { InvitationService } from './invitation.service';
import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/utilities/notification.service';
import { Router } from '@angular/router';
import { HideSideMenu } from '../general-store/general.actions';
@State<InvitationStateModel>({
  name: 'Invitation',
  defaults: {
    invitations: [],
    isLoading: false,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class InvitationState {
  constructor(
    private invitationService: InvitationService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  @Action(AddInvitation)
  addInvitation(
    { patchState, dispatch }: StateContext<InvitationStateModel>,
    action: AddInvitation
  ) {
    patchState({ isLoading: true });
    return this.invitationService.addInvitation(action.payload).pipe(
      tap(async () => {
        patchState({
          isLoading: false,
        });
        dispatch(new HideSideMenu());
        this.notificationService.openSuccessToast(
          'Invitation sent successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        this.notificationService.openErrorToast(error.error.error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(GetInvitationList)
  getInvitationList(
    { patchState }: StateContext<InvitationStateModel>,
    action: GetInvitationList
  ) {
    patchState({ isLoading: true });
    return this.invitationService.getInvitationList(action.payload).pipe(
      tap(async res => {
        patchState({
          invitations: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(AcceptInvite)
  acceptInvite(
    { patchState }: StateContext<InvitationStateModel>,
    action: AcceptInvite
  ) {
    patchState({ isLoading: true });
    return this.invitationService.acceptInvite(action.payload).pipe(
      tap(async () => {
        patchState({
          isLoading: false,
        });
        this.notificationService.openSuccessToast(
          'You have successfully signed up. Please login to continue'
        );
        this.ngZone.run(() => {
          this.router.navigate(['/'], { replaceUrl: true });
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        this.notificationService.openErrorToast(error.error.error.message);
        return throwError(() => error);
      })
    );
  }
}
