import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { TextTransformPipe } from '../../../pipes/text-transform.pipe';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { TableActionButtonComponent } from '../table-action-button/table-action-button.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule, TablePageEvent } from 'primeng/table';
import { PrimeNGConfig, SortEvent } from 'primeng/api';
import {
  Header,
  Pagination,
  TableActionEvent,
} from '../../../../store/general-store/general.model';
import { ButtonModule } from 'primeng/button';
import { debounceTime } from 'rxjs';
import { ExportData } from '../../../../store/general-store/general.actions';

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [
    TableModule,
    TextTransformPipe,
    ReactiveFormsModule,
    TooltipModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    SkeletonModule,
    TableActionButtonComponent,
    MultiSelectModule,
    FormsModule,
    ButtonModule,
  ],
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss',
})
export class DataTableComponent implements OnInit {
  //Todo: Add proper types
  @Input() isProcessing$: Signal<boolean> = signal(true);
  @Input() selectedFilters: Record<string, any> = {};
  @Input() headers$: Signal<Header[]> = signal([]);
  @Input() data$: Signal<any[]> = signal([]);
  @Input() pagination$: Signal<Pagination> = signal({
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
    itemsPerPage: 0,
  });
  @Input() showTableToolbar = true;
  @Input() allowSorting = true;
  @Input() allowFiltering = true;
  @Input() export!: string;
  @Input() first = 0;
  @Input() rows = 0;
  @Input() sortBy = '';
  @Input() sortOrder = 1;
  @Output() actionClickedEvent = new EventEmitter<TableActionEvent>();
  @Output() refreshEvent = new EventEmitter();

  visibleHeaders: Header[] = [];
  skeleton: string[] = [];
  search = new FormControl('');

  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store
  ) {
    this.skeleton = Array.from({ length: 5 }).map((_, i) => `Item #${i}`);
    effect(() => {
      if (this.visibleHeaders.length === 0) {
        this.visibleHeaders = this.headers$().filter(h => h.isVisibleHeader);
      }
    });
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.search.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.refresh();
    });
  }

  next() {
    this.first = this.first + this.rows;
    this.refresh();
  }

  prev() {
    this.first = this.first - this.rows;
    this.refresh();
  }

  pageChange(event: TablePageEvent) {
    this.first = event.first;
    this.rows = event.rows;
    this.refresh();
  }

  isLastPage(): boolean {
    return this.data$() ? this.first === this.data$().length - this.rows : true;
  }

  isFirstPage(): boolean {
    return this.data$() ? this.first === 0 : true;
  }

  getFilterableHeaders() {
    return this.headers$()
      .filter(h => h.isVisibleHeader && h.filterable)
      .map(h => h.key);
  }

  selectFilters(value: string, key: string) {
    this.selectedFilters = {
      ...this.selectedFilters,
      [key]: value,
    };
  }

  refresh() {
    this.refreshEvent.emit({
      first: this.first,
      rows: this.rows,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
      search: this.search.value,
      filters: this.selectedFilters,
    });
  }

  sort(event: SortEvent) {
    this.sortBy = event.field || '';
    this.sortOrder = event.order || 1;
    this.refresh();
  }

  clearAllFilters() {
    this.selectedFilters = {};
    this.search.patchValue('');
  }

  actionClicked(event: string, id: number, data: any) {
    this.actionClickedEvent.emit({
      event,
      id,
      data,
    });
  }

  exportData() {
    this.store.dispatch(new ExportData({ model: this.export }));
  }
}
